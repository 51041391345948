<template>
  <div class="motherboard">
    <svg
      id="Capa_1"
      enable-background="new 0 0 512 512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="w-100"
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="217.685"
        x2="257.371"
        y1="323.856"
        y2="323.856"
      >
        <stop
          offset="0"
          stop-color="#0d2627"
        />
        <stop
          offset="1"
          stop-color="#1a4c4e"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000149368917732030896930000011135000814243735685_"
        gradientTransform="matrix(.866 .5 -1 .577 112.247 612.871)"
        gradientUnits="userSpaceOnUse"
        x1="-610.445"
        x2="18.376"
        y1="-386.13"
        y2="-386.13"
      >
        <stop
          offset="0"
          stop-color="#236568"
        />
        <stop
          offset="1"
          stop-color="#06daae"
        />
      </linearGradient>
      <linearGradient id="lg1">
        <stop
          offset="0"
          stop-color="#221c36"
        />
        <stop
          offset="1"
          stop-color="#43386b"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000169542026169510635190000002939795749309672383_"
        gradientUnits="userSpaceOnUse"
        x1="203.182"
        x2="226.265"
        xlink:href="#lg1"
        y1="138.425"
        y2="138.425"
      />
      <linearGradient id="lg2">
        <stop
          offset="0"
          stop-color="#a996eb"
        />
        <stop
          offset="1"
          stop-color="#d7d1eb"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000151520362111675599680000016625322195896843690_"
        gradientUnits="userSpaceOnUse"
        x1="210.512"
        x2="222.318"
        xlink:href="#lg2"
        y1="138.582"
        y2="138.582"
      />
      <linearGradient id="lg3">
        <stop
          offset="0"
          stop-color="#43386b"
        />
        <stop
          offset="1"
          stop-color="#594a8e"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000148633329394205874030000014900163036454011778_"
        gradientUnits="userSpaceOnUse"
        x1="205.777"
        x2="226.576"
        xlink:href="#lg3"
        y1="114.557"
        y2="126.565"
      />
      <linearGradient
        id="SVGID_00000078749470440994706930000016469231577628200837_"
        gradientUnits="userSpaceOnUse"
        x1="323.262"
        x2="346.346"
        xlink:href="#lg1"
        y1="134.298"
        y2="134.298"
      />
      <linearGradient
        id="SVGID_00000167389268312854520100000000578455879531902385_"
        gradientUnits="userSpaceOnUse"
        x1="323.262"
        x2="346.345"
        xlink:href="#lg2"
        y1="143.68"
        y2="143.68"
      />
      <linearGradient
        id="SVGID_00000078742602698602856180000001984587366411151789_"
        gradientUnits="userSpaceOnUse"
        x1="325.858"
        x2="346.657"
        xlink:href="#lg3"
        y1="110.43"
        y2="122.438"
      />
      <linearGradient
        id="SVGID_00000062190861874915867650000009735271789243372453_"
        gradientUnits="userSpaceOnUse"
        x1="442.054"
        x2="465.137"
        xlink:href="#lg1"
        y1="197.931"
        y2="197.931"
      />
      <linearGradient
        id="SVGID_00000153702385539905931630000001033615100978112398_"
        gradientUnits="userSpaceOnUse"
        x1="449.384"
        x2="461.19"
        xlink:href="#lg2"
        y1="198.089"
        y2="198.089"
      />
      <linearGradient
        id="SVGID_00000134945846370090479360000012632782388378085024_"
        gradientUnits="userSpaceOnUse"
        x1="444.649"
        x2="465.448"
        xlink:href="#lg3"
        y1="174.064"
        y2="186.072"
      />
      <linearGradient
        id="SVGID_00000083087265736924444720000012529398471296025525_"
        gradientUnits="userSpaceOnUse"
        x1="459.285"
        x2="482.368"
        xlink:href="#lg1"
        y1="213.357"
        y2="213.357"
      />
      <linearGradient
        id="SVGID_00000002370151330103195490000002264834348373917577_"
        gradientUnits="userSpaceOnUse"
        x1="459.285"
        x2="482.368"
        xlink:href="#lg2"
        y1="222.739"
        y2="222.739"
      />
      <linearGradient
        id="SVGID_00000150798835510690108820000018367710712162824065_"
        gradientUnits="userSpaceOnUse"
        x1="461.881"
        x2="482.679"
        xlink:href="#lg3"
        y1="189.489"
        y2="201.497"
      />
      <linearGradient id="lg4">
        <stop
          offset="0"
          stop-color="#d7d1eb"
        />
        <stop
          offset="1"
          stop-color="#f2effa"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000056425989686717331950000015460216746217544576_"
        gradientTransform="matrix(-1 0 0 1 -1887.921 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2175.708"
        x2="-2175.708"
        xlink:href="#lg4"
        y1="119.18"
        y2="114.885"
      />
      <linearGradient
        id="SVGID_00000172402789861099532690000010820962907017065882_"
        gradientTransform="matrix(-.866 .5 0 1.155 -1726.085 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="-2327.901"
        x2="-2327.901"
        xlink:href="#lg4"
        y1="627.713"
        y2="624.92"
      />
      <linearGradient
        id="SVGID_00000138560056231085708920000009766801403282744481_"
        gradientTransform="matrix(-1 0 0 1 -1887.921 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2183.936"
        x2="-2183.936"
        xlink:href="#lg4"
        y1="123.92"
        y2="119.625"
      />
      <linearGradient
        id="SVGID_00000149344820085638308980000017434980743185981871_"
        gradientTransform="matrix(-.866 .5 0 1.155 -1726.085 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="-2337.402"
        x2="-2337.402"
        xlink:href="#lg4"
        y1="635.932"
        y2="633.139"
      />
      <linearGradient
        id="SVGID_00000072985482908314866270000004453182749928260260_"
        gradientTransform="matrix(-1 0 0 1 -1887.921 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2192.163"
        x2="-2192.163"
        xlink:href="#lg4"
        y1="128.661"
        y2="124.366"
      />
      <linearGradient
        id="SVGID_00000029768432038568179570000000936242146258041999_"
        gradientTransform="matrix(-.866 .5 0 1.155 -1726.085 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="-2346.902"
        x2="-2346.902"
        xlink:href="#lg4"
        y1="644.152"
        y2="641.359"
      />
      <linearGradient
        id="SVGID_00000165942209767776915380000015735438494019464885_"
        gradientTransform="matrix(-1 0 0 1 -1887.921 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2200.391"
        x2="-2200.391"
        xlink:href="#lg4"
        y1="133.402"
        y2="129.107"
      />
      <linearGradient
        id="SVGID_00000135678996229896230050000017322715749097428393_"
        gradientTransform="matrix(-.866 .5 0 1.155 -1726.085 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="-2356.403"
        x2="-2356.403"
        xlink:href="#lg4"
        y1="652.371"
        y2="649.578"
      />
      <linearGradient
        id="SVGID_00000138539951410876336290000004055623755913605261_"
        gradientUnits="userSpaceOnUse"
        x1="238.238"
        x2="238.238"
        xlink:href="#lg4"
        y1="137.457"
        y2="132.811"
      />
      <linearGradient
        id="SVGID_00000065073141365780596610000008324958836366741175_"
        gradientTransform="matrix(.866 .5 0 1.155 -161.836 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="459.565"
        x2="459.565"
        xlink:href="#lg4"
        y1="-563.675"
        y2="-566.468"
      />
      <linearGradient
        id="SVGID_00000040553007891548815700000008505907319902831794_"
        gradientUnits="userSpaceOnUse"
        x1="247.738"
        x2="247.738"
        xlink:href="#lg4"
        y1="131.975"
        y2="127.328"
      />
      <linearGradient
        id="SVGID_00000019658208595804023920000013777188135672965786_"
        gradientTransform="matrix(.866 .5 0 1.155 -161.836 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="470.535"
        x2="470.535"
        xlink:href="#lg4"
        y1="-573.173"
        y2="-575.966"
      />
      <linearGradient
        id="SVGID_00000158004580788649915540000008430784240881661626_"
        gradientUnits="userSpaceOnUse"
        x1="257.238"
        x2="257.238"
        xlink:href="#lg4"
        y1="126.466"
        y2="121.82"
      />
      <linearGradient
        id="SVGID_00000102542000909248071100000004159874926152022962_"
        gradientTransform="matrix(.866 .5 0 1.155 -161.836 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="481.506"
        x2="481.506"
        xlink:href="#lg4"
        y1="-582.694"
        y2="-585.487"
      />
      <linearGradient
        id="SVGID_00000110461297535936512600000005680015635518671014_"
        gradientUnits="userSpaceOnUse"
        x1="266.738"
        x2="266.738"
        xlink:href="#lg4"
        y1="120.981"
        y2="116.334"
      />
      <linearGradient
        id="SVGID_00000150100326074238365890000009974737921474444195_"
        gradientTransform="matrix(.866 .5 0 1.155 -161.836 558.055)"
        gradientUnits="userSpaceOnUse"
        x1="492.476"
        x2="492.476"
        xlink:href="#lg4"
        y1="-592.195"
        y2="-594.988"
      />
      <linearGradient
        id="SVGID_00000046306155146875499370000015684622293827568319_"
        gradientUnits="userSpaceOnUse"
        x1="269.267"
        x2="275.513"
        xlink:href="#lg1"
        y1="146.519"
        y2="146.519"
      />
      <linearGradient
        id="SVGID_00000030463006518841161520000018248502783206790325_"
        gradientTransform="matrix(.866 .5 -1 .577 112.247 612.871)"
        gradientUnits="userSpaceOnUse"
        x1="-408.063"
        x2="-362.649"
        xlink:href="#lg3"
        y1="-496.768"
        y2="-496.768"
      />
      <linearGradient id="lg5">
        <stop
          offset="0"
          stop-color="#f2effa"
        />
        <stop
          offset=".0915"
          stop-color="#eae7f6"
        />
        <stop
          offset=".3195"
          stop-color="#dcd7ee"
        />
        <stop
          offset=".4975"
          stop-color="#d7d1eb"
        />
        <stop
          offset="1"
          stop-color="#f2effa"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000056426229743543077720000014582716533021295783_"
        gradientUnits="userSpaceOnUse"
        x1="263.274"
        x2="260.198"
        xlink:href="#lg5"
        y1="156.681"
        y2="162.008"
      />
      <linearGradient
        id="SVGID_00000068653159173334100200000013762771077604891777_"
        gradientUnits="userSpaceOnUse"
        x1="263.69"
        x2="263.69"
        xlink:href="#lg4"
        y1="164.545"
        y2="159.291"
      />
      <linearGradient
        id="SVGID_00000063625173115734354600000011057199305341903506_"
        gradientUnits="userSpaceOnUse"
        x1="255.05"
        x2="251.974"
        xlink:href="#lg5"
        y1="151.922"
        y2="157.249"
      />
      <linearGradient
        id="SVGID_00000137837543919379592140000009018158525416618893_"
        gradientUnits="userSpaceOnUse"
        x1="255.466"
        x2="255.466"
        xlink:href="#lg4"
        y1="159.786"
        y2="154.532"
      />
      <linearGradient
        id="SVGID_00000117670980761459270990000017340413896430716317_"
        gradientUnits="userSpaceOnUse"
        x1="246.825"
        x2="243.75"
        xlink:href="#lg5"
        y1="147.163"
        y2="152.491"
      />
      <linearGradient
        id="SVGID_00000021822330609813271170000017999900871496642998_"
        gradientUnits="userSpaceOnUse"
        x1="247.241"
        x2="247.241"
        xlink:href="#lg4"
        y1="155.028"
        y2="149.773"
      />
      <linearGradient
        id="SVGID_00000093867404577437964770000015666312081355312052_"
        gradientUnits="userSpaceOnUse"
        x1="238.601"
        x2="235.525"
        xlink:href="#lg5"
        y1="142.404"
        y2="147.732"
      />
      <linearGradient
        id="SVGID_00000043419672561439105380000004777608466998025652_"
        gradientUnits="userSpaceOnUse"
        x1="239.017"
        x2="239.017"
        xlink:href="#lg4"
        y1="150.269"
        y2="145.015"
      />
      <linearGradient
        id="SVGID_00000173845814220186646370000011627548325418290076_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2583.523"
        x2="-2586.599"
        xlink:href="#lg5"
        y1="156.329"
        y2="161.656"
      />
      <linearGradient
        id="SVGID_00000003063669923780222700000009105553854332448135_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2583.107"
        x2="-2583.107"
        xlink:href="#lg4"
        y1="164.193"
        y2="158.939"
      />
      <linearGradient
        id="SVGID_00000078022631442142853300000010313490145800708484_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2593.026"
        x2="-2596.102"
        xlink:href="#lg5"
        y1="150.834"
        y2="156.161"
      />
      <linearGradient
        id="SVGID_00000138573383803071406530000005721679896246428040_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2592.61"
        x2="-2592.61"
        xlink:href="#lg4"
        y1="158.698"
        y2="153.444"
      />
      <linearGradient
        id="SVGID_00000070120170676299919890000016911910265466034090_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2602.526"
        x2="-2605.602"
        xlink:href="#lg5"
        y1="145.345"
        y2="150.673"
      />
      <linearGradient
        id="SVGID_00000074413960131726000960000007820614369623872643_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2602.11"
        x2="-2602.11"
        xlink:href="#lg4"
        y1="153.21"
        y2="147.955"
      />
      <linearGradient
        id="SVGID_00000101800064992321155940000017608659806596885661_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2612.027"
        x2="-2615.103"
        xlink:href="#lg5"
        y1="139.864"
        y2="145.192"
      />
      <linearGradient
        id="SVGID_00000090268382166557633720000008668594659093419921_"
        gradientTransform="matrix(-1 0 0 1 -2301.656 0)"
        gradientUnits="userSpaceOnUse"
        x1="-2611.611"
        x2="-2611.611"
        xlink:href="#lg4"
        y1="147.729"
        y2="142.474"
      />
      <linearGradient
        id="SVGID_00000009563614254617003150000017229123612664981653_"
        gradientUnits="userSpaceOnUse"
        x1="94.657"
        x2="117.74"
        xlink:href="#lg1"
        y1="274.383"
        y2="274.383"
      />
      <linearGradient
        id="SVGID_00000013163608532368698930000008329379273607700651_"
        gradientUnits="userSpaceOnUse"
        x1="94.657"
        x2="117.74"
        xlink:href="#lg2"
        y1="283.766"
        y2="283.766"
      />
      <linearGradient
        id="SVGID_00000078749571228726284020000013562258031565295527_"
        gradientUnits="userSpaceOnUse"
        x1="97.252"
        x2="118.051"
        xlink:href="#lg3"
        y1="250.516"
        y2="262.524"
      />
      <linearGradient
        id="SVGID_00000125585559488587866450000011212977104501373329_"
        gradientUnits="userSpaceOnUse"
        x1="187.938"
        x2="203.383"
        xlink:href="#lg2"
        y1="307.719"
        y2="307.719"
      />
      <linearGradient
        id="SVGID_00000116211991488870189800000005234492015700121535_"
        gradientTransform="matrix(.866 .5 -1 .577 112.247 612.871)"
        gradientUnits="userSpaceOnUse"
        x1="-332.042"
        x2="-231.041"
        xlink:href="#lg4"
        y1="-334.417"
        y2="-334.417"
      />
      <linearGradient
        id="SVGID_00000093897957508951796400000008997269453255745935_"
        gradientUnits="userSpaceOnUse"
        x1="347.956"
        x2="350.922"
        xlink:href="#lg1"
        y1="301.658"
        y2="306.795"
      />
      <linearGradient
        id="SVGID_00000060732955448181663040000014299584229105968819_"
        gradientUnits="userSpaceOnUse"
        x1="347.956"
        x2="350.922"
        xlink:href="#lg1"
        y1="312.827"
        y2="317.963"
      />
      <linearGradient
        id="SVGID_00000023974173432870124170000012708139480127458692_"
        gradientUnits="userSpaceOnUse"
        x1="347.956"
        x2="350.922"
        xlink:href="#lg1"
        y1="323.995"
        y2="329.132"
      />
      <linearGradient
        id="SVGID_00000041281277483256874520000014498366262113257858_"
        gradientUnits="userSpaceOnUse"
        x1="249.273"
        x2="131.478"
        xlink:href="#lg4"
        y1="289.224"
        y2="221.216"
      />
      <linearGradient
        id="SVGID_00000085222362970041360050000002305593730093433526_"
        gradientUnits="userSpaceOnUse"
        x1="225.441"
        x2="215.81"
        xlink:href="#lg4"
        y1="253.883"
        y2="253.883"
      />
      <linearGradient
        id="SVGID_00000134215598647352664050000007321484936510525109_"
        gradientTransform="matrix(.866 .5 -1 .577 112.247 612.871)"
        gradientUnits="userSpaceOnUse"
        x1="-305.753"
        x2="-259.234"
        y1="-348.78"
        y2="-348.78"
      >
        <stop
          offset="0"
          stop-color="#1a4c4e"
        />
        <stop
          offset="1"
          stop-color="#236568"
        />
      </linearGradient>
      <linearGradient
        id="SVGID_00000003090756758697131630000012475942418041095302_"
        gradientUnits="userSpaceOnUse"
        x1="195.909"
        x2="218.992"
        xlink:href="#lg1"
        y1="344.036"
        y2="344.036"
      />
      <linearGradient
        id="SVGID_00000057871038796789021640000009632435625490914720_"
        gradientUnits="userSpaceOnUse"
        x1="195.909"
        x2="218.991"
        xlink:href="#lg2"
        y1="353.418"
        y2="353.418"
      />
      <linearGradient
        id="SVGID_00000168099474074618972960000005252052874611079597_"
        gradientUnits="userSpaceOnUse"
        x1="198.504"
        x2="219.303"
        xlink:href="#lg3"
        y1="320.168"
        y2="332.176"
      />
      <linearGradient
        id="SVGID_00000068637391096514984970000008987944940180862365_"
        gradientUnits="userSpaceOnUse"
        x1="216.383"
        x2="239.466"
        xlink:href="#lg1"
        y1="356.25"
        y2="356.25"
      />
      <linearGradient
        id="SVGID_00000080196219715048105880000007703754966831799952_"
        gradientUnits="userSpaceOnUse"
        x1="216.383"
        x2="239.466"
        xlink:href="#lg2"
        y1="365.632"
        y2="365.632"
      />
      <linearGradient
        id="SVGID_00000032625896719514121660000008409138431806236844_"
        gradientUnits="userSpaceOnUse"
        x1="218.978"
        x2="239.777"
        xlink:href="#lg3"
        y1="332.382"
        y2="344.39"
      />
      <linearGradient
        id="SVGID_00000103955191517339197980000009352072386682160042_"
        gradientUnits="userSpaceOnUse"
        x1="22.789"
        x2="45.872"
        xlink:href="#lg1"
        y1="257.078"
        y2="257.078"
      />
      <linearGradient
        id="SVGID_00000027564511399939799700000015426680783502438283_"
        gradientUnits="userSpaceOnUse"
        x1="22.789"
        x2="45.872"
        xlink:href="#lg2"
        y1="266.46"
        y2="266.46"
      />
      <linearGradient
        id="SVGID_00000162344219981977923250000000987428091327079081_"
        gradientUnits="userSpaceOnUse"
        x1="25.384"
        x2="46.183"
        xlink:href="#lg3"
        y1="233.21"
        y2="245.218"
      />
      <linearGradient
        id="SVGID_00000004509389167188845820000003009334646940758429_"
        gradientUnits="userSpaceOnUse"
        x1="41.926"
        x2="65.01"
        xlink:href="#lg1"
        y1="268.127"
        y2="268.127"
      />
      <linearGradient
        id="SVGID_00000077285101702899393200000018374727566845213631_"
        gradientUnits="userSpaceOnUse"
        x1="41.926"
        x2="65.009"
        xlink:href="#lg2"
        y1="277.509"
        y2="277.509"
      />
      <linearGradient
        id="SVGID_00000067927137665479434730000005279695405424301473_"
        gradientUnits="userSpaceOnUse"
        x1="44.522"
        x2="65.321"
        xlink:href="#lg3"
        y1="244.259"
        y2="256.267"
      />
      <linearGradient
        id="SVGID_00000137837862994557220200000008315108307457152412_"
        gradientUnits="userSpaceOnUse"
        x1="117.741"
        x2="140.825"
        xlink:href="#lg1"
        y1="315.708"
        y2="315.708"
      />
      <linearGradient
        id="SVGID_00000018228062284039663670000001646013638794789504_"
        gradientUnits="userSpaceOnUse"
        x1="125.071"
        x2="136.878"
        xlink:href="#lg2"
        y1="315.865"
        y2="315.865"
      />
      <linearGradient
        id="SVGID_00000157300711157383693460000006899104842302116279_"
        gradientUnits="userSpaceOnUse"
        x1="120.337"
        x2="141.136"
        xlink:href="#lg3"
        y1="291.84"
        y2="303.848"
      />
      <linearGradient
        id="SVGID_00000127041922819424798790000016592941753489037704_"
        gradientUnits="userSpaceOnUse"
        x1="136.878"
        x2="159.962"
        xlink:href="#lg1"
        y1="326.757"
        y2="326.757"
      />
      <linearGradient
        id="SVGID_00000163066373929229275460000003869055764466959262_"
        gradientUnits="userSpaceOnUse"
        x1="144.208"
        x2="156.015"
        xlink:href="#lg2"
        y1="326.915"
        y2="326.915"
      />
      <linearGradient
        id="SVGID_00000052819602764052745120000010753658345389154994_"
        gradientUnits="userSpaceOnUse"
        x1="139.474"
        x2="160.273"
        xlink:href="#lg3"
        y1="302.889"
        y2="314.898"
      />
      <linearGradient
        id="SVGID_00000057842539668151147150000011724446584951547781_"
        gradientUnits="userSpaceOnUse"
        x1="247.883"
        x2="270.966"
        xlink:href="#lg1"
        y1="213.784"
        y2="213.784"
      />
      <linearGradient
        id="SVGID_00000098923078714657507630000000247089103760883885_"
        gradientUnits="userSpaceOnUse"
        x1="255.212"
        x2="267.019"
        xlink:href="#lg2"
        y1="213.941"
        y2="213.941"
      />
      <linearGradient
        id="SVGID_00000078759727327069242270000010583525464561305219_"
        gradientUnits="userSpaceOnUse"
        x1="250.478"
        x2="271.277"
        xlink:href="#lg3"
        y1="189.916"
        y2="201.924"
      />
      <linearGradient
        id="SVGID_00000031190987291457278860000005852192285563180217_"
        gradientUnits="userSpaceOnUse"
        x1="267.02"
        x2="290.103"
        xlink:href="#lg1"
        y1="224.833"
        y2="224.833"
      />
      <linearGradient
        id="SVGID_00000030459545717557654580000002588899812223049885_"
        gradientUnits="userSpaceOnUse"
        x1="274.35"
        x2="286.156"
        xlink:href="#lg2"
        y1="224.99"
        y2="224.99"
      />
      <linearGradient
        id="SVGID_00000097462558720752924080000004919985789076156592_"
        gradientUnits="userSpaceOnUse"
        x1="269.615"
        x2="290.414"
        xlink:href="#lg3"
        y1="200.965"
        y2="212.973"
      />
      <linearGradient
        id="SVGID_00000153703683111685730250000016451653704194672543_"
        gradientUnits="userSpaceOnUse"
        x1="311.721"
        x2="334.804"
        xlink:href="#lg1"
        y1="251.88"
        y2="251.88"
      />
      <linearGradient
        id="SVGID_00000062884361013712313960000007544801043336033932_"
        gradientUnits="userSpaceOnUse"
        x1="311.721"
        x2="334.804"
        xlink:href="#lg2"
        y1="261.262"
        y2="261.262"
      />
      <linearGradient
        id="SVGID_00000170244712673995410290000008349978680958381198_"
        gradientUnits="userSpaceOnUse"
        x1="314.316"
        x2="335.115"
        xlink:href="#lg3"
        y1="228.012"
        y2="240.02"
      />
      <g>
        <path
          d="m511.993 237.889-511.985 23.666.001 12.41c.003 4.26 2.575 8.328 7.839 11.367l207.774 119.958c11.625 6.712 31.932 5.87 45.356-1.88l239.917-138.516c7.392-4.267 11.146-9.739 11.095-14.916z"
          fill="url(#SVGID_1_)"
        />
        <path
          d="m504.152 226.667-207.775-119.958c-11.625-6.712-31.932-5.87-45.356 1.88l-239.916 138.516c-13.424 7.75-14.882 19.474-3.256 26.186l207.774 119.958c11.625 6.712 31.932 5.87 45.356-1.88l239.917-138.516c13.423-7.75 14.881-19.474 3.256-26.186z"
          fill="url(#SVGID_00000149368917732030896930000011135000814243735685_)"
        />
        <g>
          <path
            d="m371.292 293.63c-.173 0-.346-.045-.5-.134l-37.156-21.452 1-1.732 36.656 21.163 91.038-52.561-4.732-2.732 1-1.732 6.232 3.598c.31.178.5.509.5.866s-.19.688-.5.866l-93.038 53.715c-.154.09-.327.135-.5.135z"
            fill="#e9e5f6"
          />
        </g>
        <g>
          <path
            d="m273.479 174.996c-1.805 0-3.535-.375-4.856-1.138l-50.815-29.267c-1.44-.831-2.232-2.03-2.232-3.375 0-1.545 1.075-3.051 2.95-4.134l52.922-30.555c3.289-1.898 8.122-2.079 11.005-.415l50.814 29.267s.001 0 .001.001c1.439.831 2.231 2.029 2.231 3.375 0 1.545-1.075 3.051-2.949 4.133l-52.923 30.555c-1.781 1.029-4.016 1.553-6.148 1.553zm4.141-68.013c-1.8 0-3.715.434-5.173 1.276l-52.922 30.555c-1.221.705-1.95 1.603-1.95 2.402 0 .77.669 1.318 1.231 1.643l50.815 29.267c2.245 1.297 6.37 1.107 9.006-.414l52.923-30.555c1.221-.705 1.949-1.602 1.949-2.401 0-.77-.669-1.318-1.23-1.642l-50.815-29.268c-1.004-.58-2.382-.863-3.834-.863z"
            fill="#e9e5f6"
          />
        </g>
        <g>
          <path
            d="m453.098 271.114-1-1.732 46.844-27.045-25.907-14.958 1-1.732 27.407 15.824c.31.178.5.509.5.866s-.19.688-.5.866z"
            fill="#e9e5f6"
          />
        </g>
        <path
          d="m310.538 282.377c-.309-.179-.691-.179-1 0l-70.704 40.821-17.982-10.382-1 1.732 18.482 10.671c.309.179.69.179 1 0l70.704-40.821 68.11 39.323 2-1.155z"
          fill="#e9e5f6"
        />
        <path
          d="m65.287 306.453 2.02 1.166 34.377-19.091-.971-1.749z"
          fill="#e9e5f6"
        />
        <g>
          <path
            d="m103.648 312.586c-.173 0-.345-.045-.5-.134l-22.33-12.892 1-1.732 21.829 12.603 10.907-6.297 1 1.732-11.407 6.586c-.154.089-.327.134-.499.134z"
            fill="#e9e5f6"
          />
        </g>
        <path
          d="m139.243 274.78 1-1.732-87.037-50.251-2 1.155z"
          fill="#e9e5f6"
        />
        <g>
          <path
            d="m101.883 245.961h26.343v2h-26.343z"
            fill="#e9e5f6"
            transform="matrix(.866 -.5 .5 .866 -108.072 90.622)"
          />
        </g>
        <g>
          <path
            d="m125.165 258.761h26.343v2h-26.343z"
            fill="#e9e5f6"
            transform="matrix(.866 -.5 .5 .866 -111.349 103.973)"
          />
        </g>
        <path
          d="m121.187 280.361 1-1.732-82.842-47.829-2 1.155z"
          fill="#e9e5f6"
        />
        <g>
          <path
            d="m14.874 267.933c-2.913 0-5.705-.605-7.838-1.836-2.3-1.328-3.567-3.24-3.567-5.382 0-2.467 1.726-4.879 4.736-6.616 5.312-3.068 13.122-3.364 17.781-.675 2.3 1.328 3.567 3.239 3.567 5.381 0 2.467-1.726 4.879-4.736 6.616-2.881 1.664-6.496 2.512-9.943 2.512zm3.312-13.328c-2.952 0-6.091.712-8.481 2.092-2.026 1.17-3.236 2.672-3.236 4.018 0 1.314 1.124 2.239 2.067 2.784 3.689 2.129 10.458 1.821 14.781-.675 2.026-1.17 3.236-2.672 3.236-4.018 0-1.314-1.124-2.239-2.067-2.783-1.65-.953-3.915-1.418-6.3-1.418z"
            fill="#ffa538"
          />
        </g>
        <path
          d="m263.556 335.246c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.991-1.169 9.4 11.462 2.107 16.281-.675z"
          fill="#ffa538"
        />
        <g>
          <path
            d="m303.199 309.716c-2.912 0-5.705-.605-7.838-1.837-2.301-1.328-3.567-3.24-3.567-5.382 0-2.467 1.727-4.878 4.736-6.616 5.312-3.067 13.121-3.365 17.78-.675 2.301 1.328 3.567 3.239 3.567 5.381 0 2.467-1.727 4.879-4.736 6.617-2.879 1.663-6.493 2.512-9.942 2.512zm3.313-13.328c-2.952 0-6.091.712-8.481 2.092-2.026 1.17-3.236 2.672-3.236 4.018 0 1.314 1.124 2.239 2.067 2.784 3.688 2.13 10.457 1.821 14.78-.675 2.026-1.17 3.236-2.672 3.236-4.018 0-1.314-1.124-2.239-2.067-2.784-1.649-.952-3.913-1.417-6.299-1.417z"
            fill="#ffa538"
          />
        </g>
        <g>
          <path
            d="m475.133 250.153c-2.912 0-5.705-.605-7.838-1.837-2.301-1.328-3.567-3.239-3.567-5.382 0-2.467 1.727-4.878 4.736-6.616 5.311-3.068 13.122-3.364 17.78-.675 2.301 1.328 3.567 3.24 3.567 5.382.001 2.467-1.726 4.878-4.735 6.616-2.881 1.663-6.495 2.512-9.943 2.512zm3.312-13.328c-2.952 0-6.091.712-8.481 2.092-2.026 1.17-3.236 2.672-3.236 4.018 0 1.314 1.124 2.239 2.067 2.784 3.688 2.13 10.458 1.82 14.781-.675 2.026-1.17 3.236-2.672 3.235-4.018 0-1.314-1.124-2.239-2.067-2.784-1.648-.952-3.913-1.417-6.299-1.417z"
            fill="#ffa538"
          />
        </g>
        <path
          d="m282.282 116.122c3.385-1.954 3.753-4.911.821-6.603-2.931-1.693-8.052-1.48-11.437.474s-3.753 4.911-.821 6.603c2.931 1.693 8.052 1.481 11.437-.474z"
          fill="#ffa538"
        />
        <g>
          <path
            d="m167.849 351.524c-.173 0-.345-.045-.5-.134l-38.566-22.266 1-1.732 38.066 21.977 12.453-7.19 1 1.732-12.953 7.478c-.155.091-.327.135-.5.135z"
            fill="#e9e5f6"
          />
        </g>
        <g>
          <path
            d="m269.211 186.452h2v96.249h-2z"
            fill="#e9e5f6"
            transform="matrix(.5 -.866 .866 .5 -68.044 351.298)"
          />
        </g>
        <g>
          <path
            d="m205.261 366.843c-3.686 0-7.211-.745-9.842-2.264-2.723-1.572-4.223-3.796-4.223-6.261 0-2.9 2.103-5.777 5.768-7.894 6.632-3.83 16.733-4.231 22.517-.892 2.724 1.572 4.224 3.796 4.223 6.262 0 2.9-2.102 5.777-5.768 7.893-3.615 2.087-8.261 3.156-12.675 3.156zm4.376-17.606c-4.049 0-8.332.99-11.674 2.919-3.03 1.75-4.768 3.995-4.768 6.162 0 1.721 1.145 3.329 3.223 4.529 5.231 3.02 14.435 2.62 20.517-.892 3.03-1.75 4.768-3.995 4.768-6.161.001-1.721-1.145-3.329-3.223-4.53-2.356-1.361-5.521-2.027-8.843-2.027z"
            fill="#e9e5f6"
          />
        </g>
        <g>
          <path
            d="m225.735 378.543c-3.687 0-7.211-.745-9.842-2.264-2.724-1.572-4.224-3.796-4.224-6.261 0-2.9 2.103-5.777 5.768-7.894 6.634-3.83 16.736-4.23 22.517-.892 2.724 1.572 4.224 3.796 4.224 6.262 0 2.9-2.103 5.777-5.768 7.894-3.615 2.086-8.261 3.155-12.675 3.155zm4.377-17.606c-4.049 0-8.333.99-11.674 2.919-3.03 1.749-4.768 3.995-4.768 6.162 0 1.721 1.145 3.329 3.224 4.529 5.23 3.02 14.434 2.62 20.517-.892 3.03-1.749 4.768-3.995 4.768-6.161 0-1.721-1.145-3.33-3.224-4.53-2.357-1.361-5.522-2.027-8.843-2.027z"
            fill="#e9e5f6"
          />
        </g>
        <path
          d="m75.873 280.842-27.473 15.862 2 1.155 27.991-16.161c.311-.179.502-.512.5-.871s-.196-.69-.509-.866l-62.847-35.413-2.012 1.161z"
          fill="#e9e5f6"
        />
        <g>
          <path
            d="m226.265 118.741-23.083 1.859v31.825l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.648-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000169542026169510635190000002939795749309672383_)"
          />
          <path
            d="m222.318 119.059-11.807.951v37.92c3.864.545 8.413-.186 11.767-2.123.014-.008.025-.017.039-.025v-36.723z"
            fill="url(#SVGID_00000151520362111675599680000016625322195896843690_)"
          />
          <path
            d="m222.279 124.084c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.99-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000148633329394205874030000014900163036454011778_)"
          />
          <path
            d="m219.695 122.592c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m346.346 114.615-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000078749470440994706930000016469231577628200837_)"
          />
          <path
            d="m346.345 136.784-.008.001c.013 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.085-2.798-2.537-2.809-4.058l-.008.001v6.148l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000167389268312854520100000000578455879531902385_)"
          />
          <path
            d="m342.36 119.957c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.991-1.169 9.4c4.173 2.41 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000078742602698602856180000001984587366411151789_)"
          />
          <path
            d="m339.776 118.466c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186c2.746 1.585 7.543 1.386 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m442.581 189.587v7.053l-8.97 5.179-1.633-9.446z"
            fill="#ffc538"
          />
          <path
            d="m366.405 155.965v7.053l67.206 38.801v-8.493z"
            fill="#ffa538"
          />
          <path
            d="m366.405 155.965 8.97-5.179 67.206 38.801-8.97 5.179z"
            fill="#f7e041"
          />
          <path
            d="m375.375 153.873 61.858 35.714-3.622 2.092-61.858-35.714z"
            fill="#8b451c"
          />
        </g>
        <g>
          <path
            d="m465.137 178.248-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000062190861874915867650000009735271789243372453_)"
          />
          <path
            d="m461.19 178.566-11.807.951v37.92c3.864.545 8.413-.186 11.767-2.123.014-.008.025-.017.039-.025v-36.723z"
            fill="url(#SVGID_00000153702385539905931630000001033615100978112398_)"
          />
          <path
            d="m461.151 183.591c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.99-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000134945846370090479360000012632782388378085024_)"
          />
          <path
            d="m458.567 182.099c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m482.368 193.673-23.083 1.859v31.825l.007-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.648-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000083087265736924444720000012529398471296025525_)"
          />
          <path
            d="m482.368 215.842-.008.001c.012 1.855-1.33 3.813-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.085-2.798-2.537-2.809-4.058l-.007.001v6.148l.007-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.648-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000002370151330103195490000002264834348373917577_)"
          />
          <path
            d="m478.382 199.016c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.99-1.169 9.4 11.463 2.107 16.281-.675z"
            fill="url(#SVGID_00000150798835510690108820000018367710712162824065_)"
          />
          <path
            d="m475.798 197.524c3.171-1.831 3.515-4.6.769-6.186-2.746-1.585-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186c2.747 1.586 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g
          class="motherboard-cpu-group"
          :class="{'motherboard-cpu-group-active': deviceArea.value === 'cpu'}"
          @click="submitEditArea('cpu')"
        >
          <g>
            <g>
              <path
                d="m283.104 113.153c.716-.414 1.295-.492 1.695-.258l2.165 1.249 2.44-1.431 4.18 2.413-5.456 4.733-4.118-2.381.003-.003c-.373-.167-.583-.623-.583-1.349 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000056425989686717331950000015460216746217544576_)"
              />
              <path
                d="m287.284 115.565c1.425-.825 2.31-.316 2.31 1.328 0 .347.187.454.488.279l3.502-2.047v2.143l-4.054 2.366c-1.185.689-1.921.269-1.921-1.096 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000172402789861099532690000010820962907017065882_)"
              />
            </g>
            <g>
              <path
                d="m291.331 117.893c.716-.414 1.295-.492 1.695-.258l2.165 1.249 2.44-1.432 4.18 2.413-5.456 4.733-4.118-2.381.003-.003c-.373-.167-.583-.623-.583-1.349 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000138560056231085708920000009766801403282744481_)"
              />
              <path
                d="m295.511 120.306c1.425-.825 2.31-.316 2.31 1.328 0 .347.187.453.488.278l3.502-2.047v2.143l-4.054 2.366c-1.185.689-1.921.269-1.921-1.096 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000149344820085638308980000017434980743185981871_)"
              />
            </g>
            <g>
              <path
                d="m299.559 122.634c.716-.414 1.295-.492 1.695-.258l2.165 1.249 2.44-1.431 4.18 2.413-5.456 4.732-4.118-2.381.003-.003c-.373-.167-.583-.623-.583-1.349 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000072985482908314866270000004453182749928260260_)"
              />
              <path
                d="m303.738 125.047c1.425-.825 2.31-.316 2.31 1.328 0 .347.187.453.488.278l3.502-2.047v2.143l-4.054 2.366c-1.185.689-1.921.269-1.921-1.096 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000029768432038568179570000000936242146258041999_)"
              />
            </g>
            <g>
              <path
                d="m307.786 127.375c.716-.414 1.295-.492 1.695-.258l2.165 1.249 2.44-1.431 4.18 2.413-5.456 4.732-4.118-2.381.003-.003c-.373-.167-.583-.623-.583-1.349 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000165942209767776915380000015735438494019464885_)"
              />
              <path
                d="m311.966 129.787c1.425-.825 2.31-.316 2.31 1.328 0 .347.187.454.488.279l3.502-2.047v2.143l-4.054 2.366c-1.185.689-1.921.269-1.921-1.096 0-.628-.338-.822-.882-.507l-.554.32v-2.142z"
                fill="url(#SVGID_00000135678996229896230050000017322715749097428393_)"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="m243.433 131.486c-.716-.414-1.295-.492-1.695-.258l-2.165 1.249-3.463-2.017-4.18 2.413 6.48 5.318 4.118-2.381-.003-.003c.373-.167.583-.623.583-1.349 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000138539951410876336290000004055623755913605261_)"
              />
              <path
                d="m239.253 133.898c-1.425-.825-2.31-.316-2.31 1.328 0 .347-.187.454-.488.279l-4.525-2.632v2.143l5.077 2.951c1.185.689 1.921.269 1.921-1.096 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000065073141365780596610000008324958836366741175_)"
              />
            </g>
            <g>
              <path
                d="m252.933 126.003c-.716-.414-1.295-.492-1.695-.258l-2.165 1.249-3.463-2.017-4.18 2.413 6.48 5.318 4.118-2.381-.003-.003c.373-.167.583-.623.583-1.349 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000040553007891548815700000008505907319902831794_)"
              />
              <path
                d="m248.753 128.416c-1.425-.825-2.31-.316-2.31 1.328 0 .347-.187.454-.488.279l-4.525-2.633v2.143l5.077 2.951c1.185.689 1.921.269 1.921-1.096 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000019658208595804023920000013777188135672965786_)"
              />
            </g>
            <g>
              <path
                d="m262.433 120.495c-.716-.414-1.295-.492-1.695-.258l-2.165 1.249-3.463-2.017-4.18 2.413 6.48 5.318 4.118-2.381-.003-.003c.373-.167.583-.623.583-1.349 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000158004580788649915540000008430784240881661626_)"
              />
              <path
                d="m258.253 122.907c-1.425-.825-2.31-.316-2.31 1.328 0 .347-.187.453-.488.279l-4.525-2.632v2.143l5.077 2.951c1.185.689 1.921.269 1.921-1.097 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000102542000909248071100000004159874926152022962_)"
              />
            </g>
            <g>
              <path
                d="m271.934 115.009c-.716-.414-1.295-.492-1.695-.258l-2.165 1.249-3.463-2.017-4.18 2.413 6.48 5.318 4.118-2.381-.003-.003c.373-.167.583-.623.583-1.349 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000110461297535936512600000005680015635518671014_)"
              />
              <path
                d="m267.754 117.422c-1.425-.825-2.31-.316-2.31 1.328 0 .347-.187.453-.488.278l-4.525-2.632v2.143l5.077 2.951c1.185.689 1.921.269 1.921-1.097 0-.628.338-.822.882-.507l.554.32v-2.142z"
                fill="url(#SVGID_00000150100326074238365890000009974737921474444195_)"
              />
            </g>
          </g>
          <path
            id="motherboard-cpu-core-bottom"
            d="m315.588 131.456-80.581 3.725v5.018c0 .671.405 1.311 1.234 1.789l32.701 18.88c1.83 1.056 5.026.924 7.139-.296l37.76-21.801c1.163-.672 1.754-1.533 1.746-2.348z"
            fill="url(#SVGID_00000046306155146875499370000015684622293827568319_)"
          />
          <!-- <div class="group-1" /> -->
          <path
            id="motherboard-cpu-core-top"
            d="m314.353 129.69-32.701-18.88c-1.83-1.056-5.026-.924-7.138.296l-37.76 21.801c-2.113 1.22-2.342 3.065-.512 4.121l32.701 18.88c1.83 1.056 5.026.924 7.139-.296l37.76-21.801c2.112-1.219 2.341-3.064.511-4.121z"
            fill="url(#SVGID_00000030463006518841161520000018248502783206790325_)"
          />
          <g>
            <g>
              <path
                d="m263.08 154.591-1.112.641c-1.425.821-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.12v2.143l3.539 2.042 2.438-2.59 3.42-6.151z"
                fill="url(#SVGID_00000056426229743543077720000014582716533021295783_)"
              />
              <path
                d="m257.222 161.189 3.578 2.064-.039 2.121-3.539-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m266.619 156.633-1.112.641c-1.425.82-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.119v2.143l2.501-1.439c1.185-.679 1.921-1.949 1.921-3.315 0-.628.338-1.213.882-1.526l.554-.319v-2.142z"
                fill="url(#SVGID_00000068653159173334100200000013762771077604891777_)"
              />
            </g>
            <g>
              <path
                d="m254.856 149.832-1.112.641c-1.425.821-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.119v2.143l3.539 2.042 2.438-2.59 3.42-6.151z"
                fill="url(#SVGID_00000063625173115734354600000011057199305341903506_)"
              />
              <path
                d="m248.998 156.43 3.578 2.065-.04 2.12-3.538-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m258.395 151.874-1.112.641c-1.425.82-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.119v2.143l2.501-1.439c1.185-.679 1.921-1.949 1.921-3.315 0-.628.338-1.213.882-1.526l.554-.32v-2.141z"
                fill="url(#SVGID_00000137837543919379592140000009018158525416618893_)"
              />
            </g>
            <g>
              <path
                d="m246.632 145.074-1.112.641c-1.425.82-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.12v2.143l3.539 2.042 2.438-2.59 3.42-6.151z"
                fill="url(#SVGID_00000117670980761459270990000017340413896430716317_)"
              />
              <path
                d="m240.774 151.671 3.578 2.065-.04 2.12-3.538-2.041z"
                fill="#d7d1eb"
              />
              <path
                d="m250.171 147.115-1.112.641c-1.425.82-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.12v2.143l2.501-1.439c1.185-.679 1.921-1.949 1.921-3.315 0-.628.338-1.213.882-1.526l.554-.319v-2.143z"
                fill="url(#SVGID_00000021822330609813271170000017999900871496642998_)"
              />
            </g>
            <g>
              <path
                d="m238.408 140.315-1.112.641c-1.425.821-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.12v2.143l3.539 2.042 2.438-2.59 3.42-6.151z"
                fill="url(#SVGID_00000093867404577437964770000015666312081355312052_)"
              />
              <path
                d="m232.549 146.913 3.578 2.064-.039 2.121-3.539-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m241.946 142.357-1.112.641c-1.425.821-2.31 2.352-2.31 3.996 0 .347-.187.669-.488.842l-1.949 1.119v2.143l2.501-1.439c1.185-.679 1.921-1.949 1.921-3.315 0-.628.338-1.213.882-1.526l.554-.32v-2.141z"
                fill="url(#SVGID_00000043419672561439105380000004777608466998025652_)"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="m282.061 154.239 1.112.641c1.425.82 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.12v2.143l-3.539 2.042-2.438-2.59-3.42-6.152z"
                fill="url(#SVGID_00000173845814220186646370000011627548325418290076_)"
              />
              <path
                d="m287.919 160.837-3.578 2.065.04 2.12 3.538-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m278.522 156.281 1.112.641c1.425.821 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.119v2.143l-2.501-1.439c-1.185-.679-1.921-1.949-1.921-3.315 0-.628-.338-1.213-.882-1.526l-.554-.319v-2.142z"
                fill="url(#SVGID_00000003063669923780222700000009105553854332448135_)"
              />
            </g>
            <g>
              <path
                d="m291.564 148.744 1.112.641c1.425.82 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.12v2.143l-3.539 2.042-2.438-2.59-3.42-6.151z"
                fill="url(#SVGID_00000078022631442142853300000010313490145800708484_)"
              />
              <path
                d="m297.423 155.342-3.578 2.064.039 2.121 3.539-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m288.026 150.786 1.112.641c1.425.82 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.119v2.143l-2.501-1.439c-1.185-.679-1.921-1.949-1.921-3.315 0-.628-.338-1.213-.882-1.526l-.554-.319v-2.142z"
                fill="url(#SVGID_00000138573383803071406530000005721679896246428040_)"
              />
            </g>
            <g>
              <path
                d="m301.064 143.256 1.112.641c1.425.82 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.12v2.143l-3.539 2.042-2.438-2.59-3.42-6.151z"
                fill="url(#SVGID_00000070120170676299919890000016911910265466034090_)"
              />
              <path
                d="m306.923 149.854-3.578 2.064.039 2.121 3.539-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m297.526 145.298 1.112.641c1.425.82 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.12v2.143l-2.501-1.439c-1.185-.679-1.921-1.949-1.921-3.315 0-.628-.338-1.213-.882-1.526l-.554-.319v-2.143z"
                fill="url(#SVGID_00000074413960131726000960000007820614369623872643_)"
              />
            </g>
            <g>
              <path
                d="m310.565 137.775 1.112.641c1.425.821 2.31 2.352 2.31 3.996 0 .347.187.67.488.842l1.949 1.12v2.143l-3.539 2.042-2.438-2.59-3.42-6.151z"
                fill="url(#SVGID_00000101800064992321155940000017608659806596885661_)"
              />
              <path
                d="m316.423 144.373-3.578 2.064.039 2.121 3.539-2.042z"
                fill="#d7d1eb"
              />
              <path
                d="m307.026 139.816 1.112.641c1.425.821 2.31 2.352 2.31 3.996 0 .347.187.669.488.842l1.949 1.119v2.143l-2.501-1.439c-1.185-.679-1.921-1.949-1.921-3.315 0-.628-.338-1.213-.882-1.526l-.554-.319v-2.142z"
                fill="url(#SVGID_00000090268382166557633720000008668594659093419921_)"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="m117.525 223.634v13.188l16.773 9.684 3.053-17.663z"
            fill="#00395e"
          />
          <path
            d="m259.965 160.764v13.188l-125.667 72.554v-15.881z"
            fill="#0072bc"
          />
          <path
            d="m259.965 160.764-16.773-9.684-125.667 72.554 16.773 9.683z"
            fill="#2f97f7"
          />
          <path
            d="m249.965 160.764-115.667 66.78-6.773-3.91 115.667-66.781z"
            fill="#00395e"
          />
        </g>
        <g>
          <path
            d="m139.743 236.465v13.188l16.773 9.684 3.053-17.663z"
            fill="#00395e"
          />
          <path
            d="m282.183 173.595v13.188l-125.667 72.554v-15.88z"
            fill="#0072bc"
          />
          <path
            d="m282.183 173.595-16.773-9.684-125.667 72.554 16.773 9.684z"
            fill="#2f97f7"
          />
          <path
            d="m272.183 173.595-115.667 66.781-6.773-3.911 115.667-66.78z"
            fill="#00395e"
          />
        </g>
        <g>
          <path
            d="m117.74 254.7-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000009563614254617003150000017229123612664981653_)"
          />
          <path
            d="m117.74 276.869-.008.001c.012 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.008.001v6.148l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000013163608532368698930000008329379273607700651_)"
          />
          <path
            d="m113.754 260.043c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.991-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000078749571228726284020000013562258031565295527_)"
          />
          <path
            d="m111.17 258.551c3.171-1.831 3.515-4.6.769-6.185-2.746-1.586-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186c2.746 1.585 7.543 1.386 10.714-.445z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m302.472 268.997-199.245 19.842.001 10.123c.001 1.489.9 2.91 2.74 3.973l72.613 41.923c4.063 2.346 11.16 2.052 15.851-.657l104.163-59.979c2.583-1.491 3.896-3.404 3.878-5.213z"
            fill="url(#SVGID_00000125585559488587866450000011212977104501373329_)"
          />
          <path
            d="m299.731 265.075-72.613-41.923c-4.063-2.346-11.16-2.051-15.851.657l-104.163 59.98c-4.691 2.709-5.201 6.806-1.138 9.151l72.613 41.923c4.063 2.346 11.16 2.052 15.851-.657l104.163-59.979c4.692-2.709 5.201-6.806 1.138-9.152z"
            fill="url(#SVGID_00000116211991488870189800000005234492015700121535_)"
          />
        </g>
        <g>
          <path
            d="m261.353 318.189-11.147-6.436v-7.356l14.746 4.358z"
            fill="#322a50"
          />
          <path
            d="m261.353 318.189v-11.266l22.623-9.114v7.356z"
            fill="#43386b"
          />
          <path
            d="m283.976 297.809-11.147-6.436-22.623 13.024 11.147 6.436z"
            fill="#594a8e"
          />
          <path
            d="m254.936 301.674 11.148 6.436 13.244-7.625-11.147-6.436z"
            fill="#f2effa"
          />
        </g>
        <g>
          <path
            d="m276.445 328.36-11.147-6.436v-7.357l14.746 4.358z"
            fill="#322a50"
          />
          <path
            d="m276.445 328.36v-11.266l22.623-9.115v7.357z"
            fill="#43386b"
          />
          <path
            d="m299.068 307.979-11.147-6.436-22.623 13.024 11.147 6.436z"
            fill="#594a8e"
          />
          <path
            d="m270.028 311.844 11.147 6.436 13.245-7.625-11.147-6.436z"
            fill="#f2effa"
          />
        </g>
        <g
          class="motherboard-memory-group"
          :class="{'motherboard-memory-group-active': deviceArea.value === 'memory'}"
          @click="submitEditArea('memory')"
        >
          <g class="motherboard-memory">
            <path
              class="motherboard-memory-front"
              d="m465.702 219.465v13.188l-16.773 9.684-3.053-17.663z"
              fill="#dfdaef"
            />
            <path
              class="motherboard-memory-left"
              d="m323.262 156.595v13.188l125.667 72.554v-15.881z"
              fill="#c5baeb"
            />
            <path
              class="motherboard-memory-top"
              d="m323.262 156.595 16.773-9.684 125.667 72.554-16.773 9.683z"
              fill="#f2effa"
            />
            <path
              d="m340.035 152.684 115.667 66.781-6.773 3.91-115.667-66.78z"
              fill="#c5baeb"
            />
          </g>
          <g class="motherboard-memory">
            <path
              class="motherboard-memory-front"
              d="m443.484 232.296v13.188l-16.773 9.684-3.053-17.663z"
              fill="#dfdaef"
            />
            <path
              class="motherboard-memory-left"
              d="m301.044 169.426v13.188l125.667 72.554v-15.88z"
              fill="#c5baeb"
            />
            <path
              class="motherboard-memory-top"
              d="m301.044 169.426 16.773-9.684 125.667 72.554-16.773 9.684z"
              fill="#f2effa"
            />
            <path
              d="m317.817 165.516 115.667 66.78-6.773 3.911-115.667-66.781z"
              fill="#c5baeb"
            />
          </g>
          <g class="motherboard-memory">
            <path
              class="motherboard-memory-front"
              d="m421.266 245.128v13.188l-16.773 9.684-3.053-17.663z"
              fill="#dfdaef"
            />
            <path
              class="motherboard-memory-left"
              d="m278.826 182.258v13.188l125.667 72.554v-15.881z"
              fill="#c5baeb"
            />
            <path
              class="motherboard-memory-top"
              d="m278.826 182.258 16.773-9.684 125.667 72.554-16.773 9.684z"
              fill="#f2effa"
            />
            <path
              d="m295.599 178.348 115.667 66.78-6.773 3.91-115.667-66.78z"
              fill="#c5baeb"
            />
          </g>
          <g class="motherboard-memory">
            <path
              class="motherboard-memory-front"
              d="m399.048 257.96v13.188l-16.773 9.684-3.053-17.663z"
              fill="#dfdaef"
            />
            <path
              class="motherboard-memory-left"
              d="m256.608 195.09v13.188l125.667 72.554v-15.881z"
              fill="#c5baeb"
            />
            <path
              class="motherboard-memory-top"
              d="m256.608 195.09 16.773-9.684 125.667 72.554-16.773 9.683z"
              fill="#f2effa"
            />
            <path
              d="m273.381 191.179 115.667 66.781-6.773 3.91-115.667-66.78z"
              fill="#c5baeb"
            />
          </g>
        </g>
        <g>
          <path
            d="m65.01 213.871v13.188l24.046 13.802 3.053-17.663z"
            fill="#c5baeb"
          />
          <path
            d="m214.723 155.119v13.188l-125.667 72.554v-15.881z"
            fill="#dfdaef"
          />
          <path
            d="m214.723 155.119-24.046-13.802-125.667 72.554 24.046 13.802z"
            fill="#f2effa"
          />
          <path
            d="m204.723 155.119-115.667 66.78-14.046-8.028 115.667-66.78z"
            fill="#c5baeb"
          />
        </g>
        <g>
          <path
            d="m338.314 346.719-21.004-12.127v-43.828l27.785 8.211z"
            fill="#c5baeb"
          />
          <path
            d="m338.314 346.719v-51.195l22.218-5.461v43.828z"
            fill="#dfdaef"
          />
          <path
            d="m340.243 312.815v-6.475l18.428-10.639v6.475z"
            fill="url(#SVGID_00000093897957508951796400000008997269453255745935_)"
          />
          <path
            d="m340.243 323.984v-6.475l18.428-10.64v6.475z"
            fill="url(#SVGID_00000060732955448181663040000014299584229105968819_)"
          />
          <path
            d="m340.243 335.152v-6.475l18.428-10.64v6.476z"
            fill="url(#SVGID_00000023974173432870124170000012708139480127458692_)"
          />
          <path
            d="m360.532 290.063-21.004-12.127-22.218 12.828 21.004 12.126z"
            fill="#f2effa"
          />
        </g>
        <g>
          <path
            d="m295.693 371.256-21.004-12.126v-34.908l27.785 8.212z"
            fill="#c5baeb"
          />
          <path
            d="m295.693 371.256v-42.273l42.627-17.174v34.907z"
            fill="#dfdaef"
          />
          <g>
            <path
              d="m329.457 321.783-24.901 14.377c-3.731 2.154-6.375 8.538-5.218 12.6l3.053 10.714c.676 2.373 2.609 3.127 4.789 1.869l19.653-11.347c2.18-1.259 4.113-4.245 4.789-7.399l3.053-14.238c1.157-5.399-1.486-8.73-5.218-6.576z"
              fill="#0072bc"
            />
            <g fill="#f2effa">
              <g>
                <path
                  d="m308.364 341.955c0-1.672-1.016-2.44-2.27-1.716s-2.27 2.666-2.27 4.337c0 1.672 1.016 2.44 2.27 1.716s2.27-2.666 2.27-4.337z"
                />
                <path
                  d="m319.277 335.654c0-1.672-1.016-2.44-2.27-1.716s-2.27 2.666-2.27 4.337c0 1.672 1.016 2.44 2.27 1.716 1.253-.723 2.27-2.665 2.27-4.337z"
                />
                <path
                  d="m330.189 329.354c0-1.672-1.016-2.44-2.27-1.716s-2.27 2.666-2.27 4.337c0 1.672 1.016 2.44 2.27 1.716 1.254-.723 2.27-2.665 2.27-4.337z"
                />
              </g>
              <g>
                <path
                  d="m313.821 349.799c0-1.672-1.016-2.44-2.27-1.716s-2.27 2.666-2.27 4.337 1.016 2.44 2.27 1.716c1.253-.723 2.27-2.665 2.27-4.337z"
                />
                <path
                  d="m324.733 343.499c0-1.672-1.016-2.44-2.27-1.716s-2.27 2.666-2.27 4.337c0 1.672 1.016 2.44 2.27 1.716 1.253-.723 2.27-2.665 2.27-4.337z"
                />
              </g>
            </g>
          </g>
          <path
            d="m338.32 311.809-21.004-12.127-42.627 24.54 21.004 12.127z"
            fill="#f2effa"
          />
        </g>
        <g>
          <path
            d="m259.717 391.968-17.727-10.235v-25.574l23.45 6.931z"
            fill="#c5baeb"
          />
          <path
            d="m259.717 391.968v-31.791l35.976-14.494v25.573z"
            fill="#dfdaef"
          />
          <path
            d="m295.693 345.683-17.727-10.235-35.976 20.711 17.727 10.235z"
            fill="#f2effa"
          />
          <path
            d="m276.567 370.929c0-4.452-2.707-6.499-6.046-4.571s-6.046 7.1-6.046 11.552 2.707 6.499 6.046 4.571c3.339-1.927 6.046-7.099 6.046-11.552z"
            fill="#ff7a85"
          />
          <path
            d="m274.492 372.127c0-2.924-1.778-4.269-3.971-3.002-2.193 1.266-3.971 4.664-3.971 7.588s1.778 4.269 3.971 3.002c2.193-1.266 3.971-4.663 3.971-7.588z"
            fill="#221c36"
          />
          <path
            d="m292.179 361.929c0-4.452-2.707-6.499-6.046-4.571s-6.046 7.1-6.046 11.552 2.707 6.499 6.046 4.571 6.046-7.1 6.046-11.552z"
            fill="#06daae"
          />
          <path
            d="m290.104 363.127c0-2.924-1.778-4.269-3.971-3.002-2.193 1.266-3.971 4.664-3.971 7.588s1.778 4.269 3.971 3.002 3.971-4.664 3.971-7.588z"
            fill="#221c36"
          />
        </g>
        <path
          d="m281.958 264.079-54.901-31.697c-3.072-1.774-8.438-1.551-11.984.497l-63.394 36.601c-3.547 2.048-3.932 5.146-.86 6.919l54.901 31.697c3.072 1.773 8.437 1.551 11.985-.497l63.394-36.6c3.545-2.049 3.931-5.147.859-6.92z"
          fill="url(#SVGID_00000041281277483256874520000014498366262113257858_)"
        />
        <path
          d="m150.818 276.398.319.184c.173-.116.351-.231.542-.341l63.394-36.601c3.547-2.048 8.913-2.27 11.984-.497l54.582 31.513c3.032-2.038 3.225-4.9.318-6.578l-54.901-31.697c-3.072-1.773-8.437-1.551-11.984.497l-63.394 36.601c-3.547 2.048-3.932 5.146-.86 6.919z"
          fill="url(#SVGID_00000085222362970041360050000002305593730093433526_)"
        />
        <path
          d="m256.395 266.48-33.497-19.34c-1.874-1.082-5.148-.946-7.312.303l-38.68 22.332c-2.164 1.249-2.399 3.14-.525 4.222l33.497 19.34c1.874 1.082 5.148.946 7.312-.303l38.68-22.332c2.164-1.25 2.399-3.14.525-4.222z"
          fill="url(#SVGID_00000134215598647352664050000007321484936510525109_)"
        />
        <g>
          <path
            d="m218.992 324.353-23.083 1.859v31.825l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000003090756758697131630000012475942418041095302_)"
          />
          <path
            d="m218.991 346.522-.008.001c.012 1.855-1.33 3.813-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.008.001v6.148l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000057871038796789021640000009632435625490914720_)"
          />
          <path
            d="m215.006 329.695c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.99-1.169 9.4 11.462 2.108 16.281-.675z"
            fill="url(#SVGID_00000168099474074618972960000005252052874611079597_)"
          />
          <path
            d="m212.422 328.204c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186c2.746 1.585 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m239.466 336.567-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000068637391096514984970000008987944940180862365_)"
          />
          <path
            d="m239.466 358.736-.008.001c.012 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.008.001v6.148l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000080196219715048105880000007703754966831799952_)"
          />
          <path
            d="m235.48 341.909c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.991-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000032625896719514121660000008409138431806236844_)"
          />
          <path
            d="m232.896 340.418c3.171-1.831 3.515-4.6.769-6.185-2.746-1.586-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186c2.747 1.584 7.543 1.385 10.714-.445z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m45.872 237.394-23.083 1.859v31.825l.007-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000103955191517339197980000009352072386682160042_)"
          />
          <path
            d="m45.872 259.564-.008.001c.013 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.007.001v6.148l.007-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000027564511399939799700000015426680783502438283_)"
          />
          <path
            d="m41.886 242.737c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.991-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000162344219981977923250000000987428091327079081_)"
          />
          <path
            d="m39.302 241.245c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m65.01 248.443-23.083 1.859v31.825l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000004509389167188845820000003009334646940758429_)"
          />
          <path
            d="m65.009 270.613-.008.001c.012 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.008.001v6.149l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000077285101702899393200000018374727566845213631_)"
          />
          <path
            d="m61.024 253.786c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.99-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000067927137665479434730000005279695405424301473_)"
          />
          <path
            d="m58.44 252.294c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m140.825 296.025-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000137837862994557220200000008315108307457152412_)"
          />
          <path
            d="m136.878 296.342-11.807.951v37.92c3.864.545 8.413-.186 11.767-2.123.014-.008.025-.017.039-.025v-36.723z"
            fill="url(#SVGID_00000018228062284039663670000001646013638794789504_)"
          />
          <path
            d="m136.838 301.367c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.991-1.169 9.4c4.173 2.41 11.463 2.108 16.281-.675z"
            fill="url(#SVGID_00000157300711157383693460000006899104842302116279_)"
          />
          <path
            d="m134.255 299.876c3.171-1.831 3.515-4.6.769-6.186s-7.543-1.387-10.714.444-3.515 4.6-.769 6.186c2.746 1.585 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m159.962 307.074-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000127041922819424798790000016592941753489037704_)"
          />
          <path
            d="m156.015 307.392-11.807.951v37.92c3.864.545 8.413-.186 11.767-2.123.014-.008.025-.017.039-.025v-36.723z"
            fill="url(#SVGID_00000163066373929229275460000003869055764466959262_)"
          />
          <path
            d="m155.975 312.417c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.991-1.169 9.4c4.174 2.409 11.463 2.107 16.281-.675z"
            fill="url(#SVGID_00000052819602764052745120000010753658345389154994_)"
          />
          <path
            d="m153.392 310.925c3.171-1.831 3.515-4.6.769-6.185-2.746-1.586-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186c2.746 1.584 7.543 1.386 10.714-.445z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m270.966 194.1-23.083 1.859v31.825l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000057842539668151147150000011724446584951547781_)"
          />
          <path
            d="m267.019 194.418-11.807.951v37.92c3.864.545 8.413-.187 11.768-2.123.014-.008.025-.017.039-.025z"
            fill="url(#SVGID_00000098923078714657507630000000247089103760883885_)"
          />
          <path
            d="m266.98 199.443c4.819-2.782 5.342-6.991 1.169-9.4s-11.462-2.107-16.281.675-5.342 6.99-1.169 9.4c4.173 2.409 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000078759727327069242270000010583525464561305219_)"
          />
          <path
            d="m264.396 197.951c3.171-1.831 3.515-4.6.769-6.186-2.746-1.585-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m290.103 205.149-23.083 1.859v31.824l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000031190987291457278860000005852192285563180217_)"
          />
          <path
            d="m286.156 205.467-11.807.951v37.92c3.864.545 8.413-.186 11.767-2.123.014-.008.025-.017.039-.025v-36.723z"
            fill="url(#SVGID_00000030459545717557654580000002588899812223049885_)"
          />
          <path
            d="m286.117 210.492c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.99-1.169 9.4 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000097462558720752924080000004919985789076156592_)"
          />
          <path
            d="m283.533 209c3.171-1.831 3.515-4.6.769-6.186-2.746-1.585-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186 7.543 1.387 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <g>
          <path
            d="m320.878 275.47c-3.997 0-7.819-.808-10.673-2.455-2.936-1.696-4.554-4.09-4.554-6.741 0-3.125 2.273-6.23 6.236-8.518 7.197-4.157 18.157-4.592 24.431-.971 2.936 1.695 4.554 4.09 4.554 6.741 0 3.125-2.274 6.23-6.236 8.518-3.924 2.266-8.967 3.426-13.758 3.426zm4.767-19.172c-4.426 0-9.105 1.081-12.757 3.189-3.328 1.921-5.236 4.395-5.236 6.786 0 1.907 1.262 3.686 3.554 5.009 5.721 3.303 15.782 2.866 22.431-.971 3.328-1.921 5.236-4.395 5.236-6.786 0-1.907-1.262-3.686-3.554-5.009-2.58-1.489-6.042-2.218-9.674-2.218z"
            fill="#e9e5f6"
          />
        </g>
        <g>
          <path
            d="m334.804 232.197-23.083 1.859v31.825l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000153703683111685730250000016451653704194672543_)"
          />
          <path
            d="m334.804 254.366-.008.001c.012 1.855-1.33 3.814-3.977 5.342-4.819 2.782-12.108 3.084-16.281.675-1.88-1.086-2.798-2.537-2.809-4.058l-.008.001v6.148l.008-.001c.01 1.521.928 2.972 2.809 4.058 4.173 2.409 11.462 2.107 16.281-.675 2.647-1.529 3.99-3.487 3.977-5.342l.008-.001z"
            fill="url(#SVGID_00000062884361013712313960000007544801043336033932_)"
          />
          <path
            d="m330.818 237.539c4.819-2.782 5.342-6.99 1.169-9.4-4.173-2.409-11.462-2.107-16.281.675s-5.342 6.991-1.169 9.4c4.173 2.41 11.462 2.107 16.281-.675z"
            fill="url(#SVGID_00000170244712673995410290000008349978680958381198_)"
          />
          <path
            d="m328.234 236.048c3.171-1.831 3.515-4.6.769-6.186-2.746-1.585-7.543-1.387-10.714.444s-3.515 4.6-.769 6.186c2.746 1.585 7.543 1.386 10.714-.444z"
            fill="#dfdaef"
          />
        </g>
        <path
          d="m179.191 372.216 2 1.155 15.228-8.792-1-1.732z"
          fill="#e9e5f6"
        />
        <path
          d="m201.561 385.131 15.332-8.852-1-1.732-16.332 9.429z"
          fill="#e9e5f6"
        />
        <g
          class="motherboard-disk-group"
          :class="{'motherboard-disk-group-active': deviceArea.value === 'disk'}"
          @click="submitEditArea('disk')"
        >
          <g>
            <path
              class="motherboard-disk-left"
              d="m418.217 263.665v12.139l15.438 8.913 2.811-16.257z"
              fill="#ab2c37"
            />
            <path
              class="motherboard-disk-front"
              d="m473.534 249.338v12.139l-39.879 23.24v-14.617z"
              fill="#ff4757"
            />
            <path
              class="motherboard-disk-top"
              d="m473.534 249.338-15.438-8.913-39.879 23.24 15.438 8.913z"
              fill="#ff7a85"
            />
            <path
              d="m464.33 249.338-30.675 17.926-6.234-3.599 30.675-17.926z"
              fill="#ab2c37"
            />
          </g>
          <g>
            <path
              class="motherboard-disk-left"
              d="m373.781 289.647v12.138l15.439 8.914 2.81-16.258z"
              fill="#ab2c37"
            />
            <path
              class="motherboard-disk-front"
              d="m429.099 275.32v12.138l-39.879 23.241v-14.617z"
              fill="#ff4757"
            />
            <path
              class="motherboard-disk-top"
              d="m429.099 275.32-15.439-8.914-39.879 23.241 15.439 8.913z"
              fill="#ff7a85"
            />
            <path
              d="m419.894 275.32-30.674 17.926-6.234-3.599 30.674-17.927z"
              fill="#ab2c37"
            />
          </g>
        </g>
      </g>
    </svg>

    <div
      class="group-tag group-1"
      @click="submitEditArea('cpu')"
    >
      <img
        :src="deviceArea.value === 'cpu' ? require('@/assets/images/icons/location_orange.png') : require('@/assets/images/icons/location.png')"
        class="group-tag-image"
      >
      <!-- _orange -->
    </div>
    <div
      class="group-tag group-2"
      @click="submitEditArea('memory')"
    >
      <img
        :src="deviceArea.value === 'memory' ? require('@/assets/images/icons/location_orange.png') : require('@/assets/images/icons/location.png')"
        class="group-tag-image"
      >
    </div>
    <div
      class="group-tag group-3"
      @click="submitEditArea('disk')"
    >
      <img
        :src="deviceArea.value === 'disk' ? require('@/assets/images/icons/location_orange.png') : require('@/assets/images/icons/location.png')"
        class="group-tag-image"
      >
    </div>

  </div>
</template>

<script>
export default {
  props: {
    deviceArea: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitEditArea(area) {
      const resolveData = {
        cpu: { label: 'CPU', value: 'cpu' },
        memory: { label: '記憶體', value: 'memory' },
        disk: { label: '硬碟', value: 'disk' },
      }
      this.$emit('click-component-area', resolveData[area])
    },
  },
}
</script>

<style lang="scss" scoped>
.group-tag {
  height: 30px;
  width: 30px;
  // background-color: #24c9ff;
  // border-radius: 50%; /* 讓元素變成圓形 */
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // color: white;
  // font-size: 16px;
  // padding: 10px;
  &:hover {
    cursor: pointer;
    transform: translate(0, -10px);
    transition: all 0.6s;
    // background-color: #ffa024;
    // animation-play-state: paused; /* 悬停时暂停动画 */
    // #motherboard-cpu-core-top {
    //   fill:  #4c3f79 !important; // #4c3f79
    // }
  }
}
.group-1 {
  position: absolute;
  top: 12%;
  left: 48%;
}

.group-2 {
  position: absolute;
  top: 28%;
  right: 25%;
}

.group-3 {
  position: absolute;
  top: 40%;
  right: 12%;
}

.motherboard {
  width: 100%;
  position: relative;
  max-width: 300px;
}
.motherboard-cpu-group {
  // animation: tilt-shaking 1s linear infinite;
  // position: relative;
  // #motherboard-cpu-core-top {
  //   animation: change-color-cpu 1.8s linear infinite;
  // }
  &:hover {
    cursor: pointer;
    // transform: scale(1.1) translate(-25px, -20px) !important;
    // transition: all 0.6s;
    // animation-play-state: paused; /* 悬停时暂停动画 */
    // #motherboard-cpu-core-top {
    //   fill:  #4c3f79 !important; // #4c3f79
    // }
  }
}

.motherboard-cpu-group-active {
  #motherboard-cpu-core-top {
    animation: change-color-cpu 1.8s linear infinite;
  }
}

.motherboard-disk-group {
  // animation: tilt-shaking 1s linear infinite;
  // .motherboard-disk-left {
  //   animation: change-color-disk-left 1s linear infinite;
  // }

  // .motherboard-disk-front {
  //   animation: change-color-disk-front 1s linear infinite;
  // }

  // .motherboard-disk-top {
  //   animation: change-color-disk-top 1s linear infinite;
  // }
  &:hover {
    cursor: pointer;
    // transform: scale(1.1) translate(-40px, -30px);
    // transition: all 0.6s;
    // animation-play-state: paused; /* 悬停时暂停动画 */
    // .motherboard-disk-left {
    //   fill:  #ab2c37 !important;
    // }

    // .motherboard-disk-front {
    //   fill:  #ff4757 !important;
    // }

    // .motherboard-disk-top {
    //   fill:  #ff7a85 !important;
    // }
  }
}

.motherboard-disk-group-active {
  .motherboard-disk-left {
    animation: change-color-disk-left 1s linear infinite;
  }

  .motherboard-disk-front {
    animation: change-color-disk-front 1s linear infinite;
  }

  .motherboard-disk-top {
    animation: change-color-disk-top 1s linear infinite;
  }
}

.motherboard-memory-group {
  // .motherboard-memory-left {
  //   animation: change-color-memory-left 2s linear infinite;
  // }

  // .motherboard-memory-front {
  //   animation: change-color-memory-front 2s linear infinite;
  // }

  // .motherboard-memory-top {
  //   animation: change-color-memory-top 2s linear infinite;
  // }
  &:hover {
    cursor: pointer;
    // transform: scale(1.1) translate(-40px, -30px);
    // transition: all 0.6s;
    // animation-play-state: paused; /* 悬停时暂停动画 */
    // .motherboard-memory-left {
    //   fill: #c5baeb !important;
    // }

    // .motherboard-memory-front {
    //   fill: #dfdaef !important;
    // }

    // .motherboard-memory-top {
    //   fill: #f2effa !important;
    // }
  }
}

.motherboard-memory-group-active {
  .motherboard-memory-left {
    animation: change-color-memory-left 2s linear infinite;
  }

  .motherboard-memory-front {
    animation: change-color-memory-front 2s linear infinite;
  }

  .motherboard-memory-top {
    animation: change-color-memory-top 2s linear infinite;
  }
}

// @keyframes tilt-shaking {
//   0% { transform: rotate(0deg); }
//   25% { transform: rotate(0.5deg); }
//   50% { transform: rotate(0deg); }
//   75% { transform: rotate(-0.5deg); }
//   100% { transform: rotate(0deg); }
// }

@keyframes change-color-memory-left {
  0% {
    fill: #ded6fc;
  }
  50% {
    fill: #beb3e3;
  }
  100% {
    fill: #ded6fc;
  }
}
@keyframes change-color-memory-front {
  0% {
    fill: #f5f2fd;
  }
  50% {
    fill: #d3cde7;
  }
  100% {
    fill: #f5f2fd;
  }
}
@keyframes change-color-memory-top {
  0% {
    fill: #f6f1f1;
  }
  50% {
    fill: #ded9eb;
  }
  100% {
    fill: #f4efef;
  }
}

@keyframes change-color-disk-left {
  0% {
    fill: #cd4853;
  }
  50% {
    fill: #95212a;
  }
  100% {
    fill: #cd4853;
  }
}
@keyframes change-color-disk-front {
  0% {
    fill: #ff5c6a;
  }
  50% {
    fill: #e63645;
  }
  100% {
    fill: #ff5c6a;
  }
}
@keyframes change-color-disk-top {
  0% {
    fill: #fb98a0;
  }
  50% {
    fill: #f46b77;
  }
  100% {
    fill: #fb98a0;
  }
}

@keyframes change-color-cpu {
  0% {
    fill: #8f82b9;
  }
  50% {
    fill: #5d4f8c;
  }
  100% {
    fill: #8f82b9;
  }
}
</style>
