<template>
  <div v-if="deviceData">
    <div>
      <device-info
        :is-card-busy="isBusy"
        :device-data-item="deviceData"
        @refresh-data="getDevicePCData"
      />

      <device-content
        :is-card-busy="isBusy"
        :device-data-item="deviceData"
        @refresh-data="getDevicePCData"
      />
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
// import {
//   BCard, BRow, BCol, BButton, BTabs, BTab, BButtonGroup, VBTooltip, BImg,
//   BCardBody, BCardHeader, BCardTitle,
// } from 'bootstrap-vue'
// import moment from 'moment'
// import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
// import { ToggleButton } from 'vue-js-toggle-button'
import store from '@/store'
// import router from '@/router'
// import AddMain from './QuestionEditMain.vue'
// import OtherMain from './QuestionEditOther.vue'
import DeviceInfo from './DeviceViewPCInfo.vue'
import DeviceContent from './DeviceViewPCContent.vue'
import { useAlert } from '@/libs/mixins/index'
import { useDevicePCEdit } from '../useDevice'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    DeviceInfo,
    DeviceContent,
  },
  // directives: {
  //   Ripple,
  //   'b-tooltip': VBTooltip,
  // },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  watch: {
    $route: 'submitRefreshData',
  },
  methods: {
  },
  setup() {
    // 註冊模組
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      isBusy,
      deviceData,
      getDevicePCData,
    } = useDevicePCEdit()

    // const {
    //   questionType,
    //   displayState,
    //   countState,
    //   resolveQuestionType,
    // } = useQuestionSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    getDevicePCData()

    return {
      isBusy,
      deviceData,
      getDevicePCData,

      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
