<template>
  <div>
    <b-overlay
      :show="isCardBusy"
      variant="transparent"
      opacity="0.5"
    >
      <b-card>
        <!-- {{ deviceDataItem }} -->
        <div class="d-flex justify-content-between align-items-center mb-1">
          <h4 class="mb-0">
            主機詳情
          </h4>

          <div class="text-nowrap d-flex justify-content-end">
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="編輯"
              class="d-flex align-items-center actions-link-btn"
              @click="onSubmitEdit"
            >
              <b-img
                src="/admin/images/table/edit3.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              v-b-tooltip.hover.focus.v-secondary
              title="重新整理"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="refreshDeviceData"
            >
              <b-img
                src="/admin/images/table/refresh.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </div>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="設備名稱"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ deviceDataItem.device_info.name ? deviceDataItem.device_info.name : '---' }}
                <!-- / {{ deviceDataItem.device_info }} -->
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="類別"
              class="m-0 selection-group"
            >
              <div class="mt-50 table-col item-description">
                電腦主機
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="IP位置"
              class="m-0 selection-group"
            >
              <!-- <div
                class="mt-50 cursor-pointer"
                @click="onSubmitEdit"
              >
                {{ deviceDataItem.assigned_ip ? deviceDataItem.assigned_ip : '---' }}
              </div> -->

              <div class="d-block selection-group d-flex align-items-center mt-50">
                <div
                  class="mr-25 text-nowrap item-description cursor-pointer"
                  @click="onSubmitEdit"
                >
                  {{ deviceDataItem.assigned_ip ? deviceDataItem.assigned_ip : '---' }}
                </div>
                <span
                  class="selection-btn-icon"
                  @click="onSubmitEdit"
                >
                  <b-img
                    src="/admin/images/table/edit.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="主機說明"
              class="m-0 selection-group"
            >
              <!-- <div class="mt-50 table-col item-description">
                {{ deviceDataItem.description ? deviceDataItem.description : '---' }}
              </div> -->

              <div class="d-block selection-group d-flex align-items-center mt-50 mb-25">
                <div
                  class="mr-25 text-nowrap item-description cursor-pointer"
                  @click="onSubmitEdit"
                >
                  {{ deviceDataItem.description ? deviceDataItem.description : '---' }}
                </div>
                <span
                  class="selection-btn-icon"
                  @click="onSubmitEdit"
                >
                  <b-img
                    src="/admin/images/table/edit.svg"
                    height="14"
                    width="14"
                    class="cursor-pointer mb-25"
                  />
                </span>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="記憶體大小"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ deviceDataItem.memory_size ? `${parseInt(deviceDataItem.memory_size, 10).toLocaleString()} GB` : '---' }}
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="硬碟大小"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ deviceDataItem.hard_disk_size ? `${parseInt(deviceDataItem.hard_disk_size, 10).toLocaleString()} GB` : '---' }}
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="使用者"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ deviceDataItem.user_info.name ? deviceDataItem.user_info.name : '---' }}
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label-cols="4"
              label="上次更新"
              class="m-0 selection-group"
            >
              <div class="mt-50">
                {{ moment(deviceDataItem.updated_at).format("YYYY-MM-DD HH:mm") }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- 編輯主機 -->
    <edit-modal
      ref="editModal"
      :device-item="deviceDataItem"
      @refetch-data="refreshDeviceData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import EditModal from '../device-list-pc/DeviceListEditModal.vue'
// import { useAlert } from '@/libs/mixins/index'
// import vSelect from 'vue-select'
import { useDevicePCEdit } from '../useDevice' // , useDeviceSetting

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BFormGroup,

    EditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isCardBusy: {
      type: Boolean,
      required: true,
    },
    deviceDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  methods: {
    moment,
    refreshDeviceData() {
      if (this.isCardBusy) return
      this.$emit('refresh-data')
    },

    // (觸發)編輯主機
    onSubmitEdit() {
      // if (this.authAbility.user !== 'write') return
      setTimeout(() => { this.$refs.editModal.getData() }, 200)
    },
  },
  setup() {
    const {
      deviceArea,
      deviceAreaOptions,
    } = useDevicePCEdit()

    return {
      deviceArea,
      deviceAreaOptions,
      // resetMetaList,
      // brandsOptions,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
.content-motherboard-area {
  background-color:#cfcfcf2b;
  border-radius: 10px;
  padding: 15px;
}

.device-area-select {
  width: 120px;
}

.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  // font-size: 1.4rem;
}
</style>
