<template>
  <div>
    <b-card>
      <!-- {{ deviceArea }} / {{ deviceAreaOptions }} -->
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h4 class="mb-0">
          硬體設置 - {{ deviceArea.label }}
        </h4>

        <v-select
          v-model="deviceArea"
          :options="deviceAreaOptions"
          :clearable="false"
          class="device-area-select"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              沒有<em>{{ search }}</em> 的相關結果
            </template>

            <div v-else>
              <small>暫無資料</small>
            </div>
          </template>
        </v-select>
      </div>

      <b-row>
        <b-col
          cols="12"
          xl="3"
          class="mb-1"
        >
          <div class="content-motherboard-area mt-2">
            <device-motherboard
              :device-area="deviceArea"
              @click-component-area="(item) => deviceArea = item"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          xl="9"
          class="mb-1"
        >
          <component
            :is="`component-${deviceArea.value}`"
            :component-data="deviceDataItem[deviceArea.value]"
            :brands-options="brandsOptions"
            :is-refresh-busy="isCardBusy"
            class="animate__animated animate__fadeIn"
            @refresh-device-data="refreshDeviceData"
          />
          <!-- <component-cpu
            :component-data="deviceDataItem.cpu"
            :brands-options="brandsOptions"
            :is-refresh-busy="isCardBusy"
            @refresh-device-data="refreshDeviceData"
          />
          <component-hard-disk
            :component-data="deviceDataItem.hard_disk"
            :brands-options="brandsOptions"
            :is-refresh-busy="isCardBusy"
            @refresh-device-data="refreshDeviceData"
          />
          <component-memory
            :component-data="deviceDataItem.memory"
            :brands-options="brandsOptions"
            :is-refresh-busy="isCardBusy"
            @refresh-device-data="refreshDeviceData"
          /> -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
// import { useAlert } from '@/libs/mixins/index'
import vSelect from 'vue-select'
import { useDevicePCEdit, useDeviceSetting } from '../useDevice'
import DeviceMotherboard from './DeviceViewPCMotherboard.vue'
import ComponentCpu from './components/cpu/Component.vue'
import ComponentDisk from './components/hard-disk/Component.vue'
import ComponentMemory from './components/memory/Component.vue'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    DeviceMotherboard,
    ComponentCpu,
    ComponentDisk,
    ComponentMemory,
  },
  props: {
    isCardBusy: {
      type: Boolean,
      required: true,
    },
    deviceDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
    }
  },
  computed: {
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  methods: {
    refreshDeviceData() {
      if (this.isCardBusy) return
      this.$emit('refresh-data')
    },
  },
  setup() {
    const searchSetting = router.currentRoute.query

    const resolveData = {
      cpu: { label: 'CPU', value: 'cpu' },
      memory: { label: '記憶體', value: 'memory' },
      disk: { label: '硬碟', value: 'disk' },
    }

    const {
      deviceArea,
      deviceAreaOptions,
    } = useDevicePCEdit()

    const {
      getMetaListData,
      brandsOptions,
    } = useDeviceSetting()

    if (resolveData[searchSetting.type]) deviceArea.value = resolveData[searchSetting.type]

    // (重新)獲取Meta
    const resetMetaList = () => {
      getMetaListData()
        .then(response => {
          const { brands } = response.data.data
          brandsOptions.value = brands
        })
    }

    resetMetaList()

    return {
      deviceArea,
      deviceAreaOptions,
      resetMetaList,
      brandsOptions,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
.content-motherboard-area {
  background-color:#cfcfcf2b;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.device-area-select {
  width: 150px;
}
</style>
