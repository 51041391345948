<template>
  <div>
    <!-- 更新硬碟(未使用) -->
    <update-modal
      v-if="selected"
      ref="updateModal"
      :device-data-item="selected"
      @refetch-data="refetchTable"
    />

    <!-- 更新硬碟(已使用) -->
    <update-use-modal
      v-if="selected"
      ref="updateUseModal"
      :device-data-item="selected"
      @refetch-data="() => $emit('refresh-device-data')"
    />

    <div>
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="mb-2"
        >
          <div class="d-flex justify-content-between align-items-center mb-50">
            <h5>配置</h5>

            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUseUpdate(null)"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="() => $emit('refresh-device-data')"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </div>
          <div class="component-list border">
            <draggable
              v-if="!isRefreshBusy"
              :list="componentData"
              tag="div"
              group="disk"
              class="list-group list-group-flush cursor-move"
              :empty-insert-threshold="300"
              style="min-height: 300px;"
              handle=".draggable-handle"
              @add="submitDiskPullAdd($event)"
            >
              <div
                v-for="(item, index) in componentData"
                :key="index"
                class="component-area mb-1"
              >
                <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                  <!-- flex-wrap -->
                  <div class="d-flex justify-content-between align-items-center">
                    <b-img
                      :src="require('@/assets/images/icons/ssd.svg')"
                      blank-color="#ccc"
                      width="28"
                      alt="disk"
                      class="mr-50"
                    />
                    <div>
                      <div class="item-description font-weight-bold d-block text-nowrap">
                        {{ item.name }}
                      </div>

                      <small>
                        <div class="item-description text-muted">{{ item.serialNumber }}</div>
                      </small>
                    </div>
                  </div>

                  <div class="d-flex align-items-center text-right">
                    <div class="d-flex align-items-center table-col">
                      <span class="text-body-heading mr-50 text-nowrap health-text">{{ item.health }} %</span>
                      <vue-apex-charts
                        v-b-tooltip.hover.focus.v-secondary
                        :title="`${item.health} / 100`"
                        type="radialBar"
                        height="30"
                        width="30"
                        :options="item.chartDataAll.options"
                        :series="item.chartDataAll.series"
                      />
                    </div>
                  </div>
                </div>

                <hr>

                <div class="component-area-footer">
                  <div class="text-right">
                    <!-- <div v-b-toggle="`collapse-use-${index}`">
                      顯示更多
                    </div> -->

                    <feather-icon
                      v-b-toggle="`collapse-use-${index}`"
                      icon="ChevronDownIcon"
                      size="12"
                    />
                  </div>

                  <b-collapse
                    :id="`collapse-use-${index}`"
                    class="mt-50"
                  >
                    <b-card no-body>
                      <b-table-simple
                        striped
                        class="table-sm"
                      >
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            品牌：
                          </b-td>
                          <b-td class="py-75">
                            {{ item.brand_id ? item.brand_info.name : '---' }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            內存大小：
                          </b-td>
                          <b-td class="py-75">
                            {{ item.size ? `${parseInt(item.size, 10).toLocaleString()} GB` : '---' }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            購買日期：
                          </b-td>
                          <b-td class="py-75">
                            <div style="min-width: 85px;">
                              {{ item.purchase_date ? moment(item.purchase_date).format('YYYY-MM-DD') : '---' }}
                            </div>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td class="text-right align-top text-nowrap py-75">
                            說明：
                          </b-td>
                          <td class="py-75">
                            <div style="min-width: 100px;">
                              {{ item.description ? item.description : '---' }}
                            </div>
                          </td>
                        </b-tr>

                        <b-tr>
                          <b-td class="text-right align-center text-nowrap py-75">
                            動作：
                          </b-td>
                          <td class="py-75">
                            <div class="d-flex">
                              <div
                                class="actions-link-btn-sm mr-25"
                                @click="onSubmitUseUpdate(item)"
                              >
                                <b-img
                                  v-b-tooltip.hover.focus.v-secondary
                                  title="編輯"
                                  src="/admin/images/table/edit.svg"
                                  class="actions-link-btn-image"
                                  rounded
                                />
                              </div>

                              <div
                                class="actions-link-btn-sm mr-25"
                                @click="submitDiskRemove(item)"
                              >
                                <b-img
                                  v-b-tooltip.hover.focus.v-secondary
                                  title="移除"
                                  src="/admin/images/table/swap.svg"
                                  class="actions-link-btn-image"
                                  rounded
                                />
                              </div>
                            </div>
                          </td>
                        </b-tr>
                      </b-table-simple>
                    </b-card>
                  </b-collapse>
                </div>
                <!-- <div>
                  {{ item }}
                </div> -->
              </div>

              <div
                v-if="componentData.length === 0"
                class="loading-area"
              >
                <div class="text-center my-5 animate__animated animate__fadeIn">
                  <b-img
                    :src="$store.state.app.notFoundImg"
                    fluid
                    width="480"
                    alt="查無資料"
                  />
                </div>
              </div>
            </draggable>

            <div
              v-else
              class="loading-area"
            >
              <b-img
                src="/admin/images/common/loading-2.png"
                rounded
                height="60"
                width="60"
              />
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center flex-md-column justify-content-center my-2"
        >
          <div class="mb-50 my-1">
            <feather-icon
              icon="ArrowRightIcon"
              class="cursor-pointer text-muted d-md-block d-none"
              size="16"
            />

            <feather-icon
              icon="ArrowUpIcon"
              class="cursor-pointer text-muted d-md-none d-block"
              size="16"
            />
          </div>

          <div class="mb-50 my-1">
            <feather-icon
              icon="ArrowLeftIcon"
              class="cursor-pointer text-muted d-md-block d-none"
              size="16"
            />

            <feather-icon
              icon="ArrowDownIcon"
              class="cursor-pointer text-muted d-md-none d-block"
              size="16"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="5"
          class="mb-2"
        >
          <div class="d-flex justify-content-between align-items-center mb-50">
            <h5>庫存</h5>

            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="篩選器"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/filter.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form style="min-width: 300px">
                    <b-form-group>
                      <label class="mb-50">
                        搜尋
                      </label>

                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="searchQuery"
                          debounce="500"
                          class="d-inline-block"
                          placeholder="搜尋..."
                        />
                        <b-input-group-append
                          v-if="searchQuery"
                          is-text
                        >
                          <feather-icon
                            icon="XIcon"
                            class="cursor-pointer text-muted"
                            @click="searchQuery = null"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>

                    <b-form-group>
                      <label class="mb-50">
                        品牌
                      </label>
                      <v-select
                        v-model="searchBrand"
                        :options="brandsOptions"
                        label="name"
                        :reduce="option => option.id"
                        placeholder="請選擇品牌"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>

                    <b-form-group>
                      <label class="mb-50">
                        購買日期
                      </label>
                      <b-input-group class="input-group-merge mr-1">
                        <flat-pickr
                          v-model="purchaseTimeRange"
                          class="form-control"
                          :config="dateConfig2"
                          placeholder="YYYY-MM-DD"
                        />

                        <b-input-group-append
                          v-if="purchaseTimeRange"
                          is-text
                        >
                          <feather-icon
                            icon="XIcon"
                            class="cursor-pointer text-muted"
                            @click="purchaseTimeRange = null"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </div>

          <div>
            <div class="component-list border">
              <draggable
                v-if="!isBusy"
                :list="tableData"
                tag="div"
                group="disk"
                class="list-group list-group-flush cursor-move"
                :empty-insert-threshold="300"
                style="min-height: 300px;"
                handle=".draggable-handle"
                @add="submitDiskPullRemove($event)"
              >
                <div
                  v-for="(item, index) in tableData"
                  :key="index"
                  class="component-area mb-1"
                >
                  <div class="d-flex justify-content-between align-items-center component-area-content draggable-handle">
                    <div class="d-flex justify-content-between align-items-center">
                      <b-img
                        :src="require('@/assets/images/icons/ssd.svg')"
                        blank-color="#ccc"
                        width="28"
                        alt="disk"
                        class="mr-50"
                      />
                      <div>
                        <div
                          v-b-tooltip.hover.focus.v-secondary
                          :title="item.name"
                          class="item-description font-weight-bold d-block text-nowrap"
                        >
                          {{ item.name }}
                        </div>

                        <small>
                          <div class="item-description text-muted">{{ item.serialNumber }}</div>
                        </small>
                      </div>
                    </div>

                    <div class="d-flex align-items-center text-right">
                      <div class="d-flex align-items-center table-col">
                        <span class="text-body-heading mr-50 text-nowrap health-text">{{ item.health }} %</span>
                        <vue-apex-charts
                          v-b-tooltip.hover.focus.v-secondary
                          :title="`${item.health} / 100`"
                          type="radialBar"
                          height="30"
                          width="30"
                          :options="item.chartDataAll.options"
                          :series="item.chartDataAll.series"
                        />
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="component-area-footer">
                    <div class="text-right">
                      <feather-icon
                        v-b-toggle="`collapse-list-${index}`"
                        icon="ChevronDownIcon"
                        size="12"
                      />
                    </div>

                    <b-collapse
                      :id="`collapse-list-${index}`"
                      class="mt-50"
                    >
                      <b-card no-body>
                        <b-table-simple
                          striped
                          class="table-sm"
                        >
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              品牌：
                            </b-td>
                            <b-td class="py-75">
                              {{ item.brand_id ? item.brand_info.name : '---' }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              內存大小：
                            </b-td>
                            <b-td class="py-75">
                              {{ item.size ? `${parseInt(item.size, 10).toLocaleString()} GB` : '---' }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              購買日期：
                            </b-td>
                            <b-td class="py-75">
                              <div style="min-width: 85px;">
                                {{ item.purchase_date ? moment(item.purchase_date).format('YYYY-MM-DD') : '---' }}
                              </div>
                            </b-td>
                          </b-tr>

                          <b-tr>
                            <b-td class="text-right align-top text-nowrap py-75">
                              說明：
                            </b-td>
                            <td class="py-75">
                              <div style="min-width: 100px;">
                                {{ item.description ? item.description : '---' }}
                              </div>
                            </td>
                          </b-tr>

                          <b-tr>
                            <b-td class="text-right align-center text-nowrap py-75">
                              動作：
                            </b-td>
                            <td class="py-75">
                              <div class="d-flex">
                                <div
                                  class="actions-link-btn-sm mr-25"
                                  @click="submitDiskAdd(item)"
                                >
                                  <b-img
                                    v-b-tooltip.hover.focus.v-secondary
                                    title="新增"
                                    src="/admin/images/table/swap.svg"
                                    class="actions-link-btn-image"
                                    rounded
                                  />
                                </div>

                                <div
                                  class="actions-link-btn-sm mr-25"
                                  @click="onSubmitUpdate(item)"
                                >
                                  <b-img
                                    v-b-tooltip.hover.focus.v-secondary
                                    title="編輯"
                                    src="/admin/images/table/edit.svg"
                                    class="actions-link-btn-image"
                                    rounded
                                  />
                                </div>

                                <div
                                  class="actions-link-btn-sm mr-25"
                                  @click="onSubmitDelete(item)"
                                >
                                  <b-img
                                    v-b-tooltip.hover.focus.v-secondary
                                    title="刪除"
                                    src="/admin/images/table/delete.svg"
                                    class="actions-link-btn-image"
                                    rounded
                                  />
                                </div>
                              </div>
                            </td>
                          </b-tr>
                        </b-table-simple>
                      </b-card>
                    </b-collapse>
                  </div>
                  <!-- <div>
                    {{ item }}
                  </div> -->
                </div>

                <div
                  v-if="tableData.length === 0"
                  class="loading-area"
                >
                  <div class="text-center my-5 animate__animated animate__fadeIn">
                    <b-img
                      :src="$store.state.app.notFoundImg"
                      fluid
                      width="480"
                      alt="查無資料"
                    />
                  </div>
                </div>
              </draggable>

              <div
                v-else
                class="loading-area"
              >
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="60"
                  width="60"
                />
              </div>
            </div>

            <!-- 顯示頁數/分頁 -->
            <div class="mx-2 m-1">
              <div class="d-flex align-items-center justify-content-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalNum"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BTableSimple, BTr, BTd, BImg, VBTooltip, BCollapse, VBToggle, BCard, BPagination,
  BDropdown, BDropdownForm, BFormGroup, BInputGroupAppend, BInputGroup, BInputGroupPrepend, BFormInput,
  // BMedia, BImg, BCardText, BLink, BThead, BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import language from 'flatpickr/dist/l10n/zh-tw'
import VueApexCharts from 'vue-apexcharts'
import draggable from 'vuedraggable'
import UpdateModal from '../../../device-list-disk/DeviceListUpdateModal.vue'
import UpdateUseModal from './ComponentUpdateModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import { useDeviceDiskList } from '../../../useDevice'

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BFormGroup,
    // BThead,
    BTr,
    // BTh,
    BTd,
    BImg,
    BCard,
    // BLink,
    BCollapse,
    BPagination,
    BDropdownForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    // BButtonGroup,
    BInputGroup,
    BDropdown,
    draggable,
    VueApexCharts,

    UpdateModal,
    UpdateUseModal,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [useSwalToast],
  props: {
    componentData: {
      type: Array,
      required: true,
    },
    brandsOptions: {
      type: Array,
      required: true,
    },
    isRefreshBusy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    moment,
    // (新增)硬碟(拖曳)
    submitDiskPullAdd(e) {
      const newComponent = this.componentData[e.newIndex]
      this.setDeviceDiskUpdate({
        disk_id: newComponent.id,
        data: {
          pc_id: this.$router.currentRoute.params.id,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.getDeviceDiskListData()
          this.$emit('refresh-device-data')
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.getDeviceDiskListData()
        })
    },

    // (新增)硬碟
    submitDiskAdd(item) {
      this.useSwalAlertInfo('設定新增', `你確定要新增硬碟 ${item.name} 到此主機上嗎?`)
        .then(result => {
          if (result.value) {
            this.setDeviceDiskUpdate({
              disk_id: item.id,
              data: {
                pc_id: this.$router.currentRoute.params.id,
              },
            })
              .then(response => {
                this.useHttpCodeAlert(response)
                this.getDeviceDiskListData()
                this.$emit('refresh-device-data')
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
              })
          }
        })
    },

    // (移除)硬碟(拖曳)
    submitDiskPullRemove(e) {
      const newComponent = this.tableData[e.newIndex]

      this.setDeviceDiskUpdate({
        disk_id: newComponent.id,
        data: {
          pc_id: null,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.getDeviceDiskListData()
          this.$emit('refresh-device-data')
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.getDeviceDiskListData()
        })
    },

    // (移除)硬碟
    submitDiskRemove(item) {
      this.useSwalAlertWarning('設定移除', `你確定要移除主機上的硬碟 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setDeviceDiskUpdate({
              disk_id: item.id,
              data: {
                pc_id: null,
              },
            })
              .then(response => {
                this.useHttpCodeAlert(response)
                this.getDeviceDiskListData()
                this.$emit('refresh-device-data')
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
              })
          }
        })
    },

    // (觸發)新增/編輯硬碟
    onSubmitUpdate(item) {
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs.updateModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (觸發)新增/編輯(已使用)硬碟
    onSubmitUseUpdate(item) {
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs.updateUseModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateUseModal.getData() }, 200)
    },

    // (刪除)硬碟
    onSubmitDelete(item) {
      // if (this.authAbility.user !== 'write') return
      this.useSwalAlertWarning('刪除硬碟', `你確定要永久刪除硬碟 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setDeviceDiskDelete({
              disk_id: item.id,
            }).then(() => {
              this.getDeviceDiskListData()
              this.useSwalAlertCenter(true, '刪除成功!', '硬碟已移除')
              // this.$emit('refresh-device-data')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    const {
      isBusy,
      unUse,
      tableData,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchBrand,
      purchaseTimeRange,
      refetchTable,

      setDeviceDiskDelete,
      setDeviceDiskUpdate,
      getDeviceDiskListData,
    } = useDeviceDiskList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    perPage.value = 5
    unUse.value = false

    getDeviceDiskListData()

    return {
      dateConfig2,
      isBusy,
      tableData,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      searchBrand,
      purchaseTimeRange,
      refetchTable,
      useAlertToast,
      useHttpCodeAlert,

      setDeviceDiskDelete,
      setDeviceDiskUpdate,
      getDeviceDiskListData,
    }
  },
}
</script>
<style lang="scss">
// @import "~@core/scss/base/pages/app-email.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  // font-size: 1.4rem;
}
.component-list {
  padding: 15px;
  height: 100%;
  border-radius: 10px;
  height: 60vh;
  overflow-y: scroll;
  .component-area {
    background-color:#cfcfcf2b;
    border-radius: 10px;
    .component-area-content {
      padding: 15px;
    }

    hr {
      border-color: #cdcdcd46;
      margin: 0;
    }

    .component-area-footer {
      padding: 5px 15px;
      cursor: auto;
    }
  }
}

@media(max-width: 768px) {
  .component-list {
    height: 100%;
    // overflow-y: scroll;
    // .component-area {
    //   background-color:#cfcfcf2b;
    //   border-radius: 10px;
    //   .component-area-content {
    //     padding: 15px;
    //   }

    //   hr {
    //     border-color: #cdcdcd46;
    //     margin: 0;
    //   }

    //   .component-area-footer {
    //     padding: 5px 15px;
    //   }
    // }
  }
}
</style>
